
    <template>
      <section class="content element-doc">
        <h2>Cascader 级联选择器</h2>
<p>当一个数据集合有清晰的层级结构时，可通过级联选择器逐级查看并选择。</p>
<h3>基础用法</h3>
<p>有两种触发子菜单的方式</p>
<demo-block>
        <div><p>只需为 Cascader 的<code>options</code>属性指定选项数组即可渲染出一个级联选择器。通过<code>props.expandTrigger</code>可以定义展开子级菜单的触发方式。</p>
</div>
        <template #source><element-demo0 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;div&gt;
    &lt;div class=&quot;block&quot;&gt;
      &lt;span class=&quot;demonstration&quot;&gt;默认 click 触发子菜单&lt;/span&gt;
      &lt;el-cascader
        v-model=&quot;value&quot;
        :options=&quot;options&quot;
        @change=&quot;handleChange&quot;
      &gt;&lt;/el-cascader&gt;
    &lt;/div&gt;
    &lt;div class=&quot;block&quot;&gt;
      &lt;span class=&quot;demonstration&quot;&gt;hover 触发子菜单&lt;/span&gt;
      &lt;el-cascader
        v-model=&quot;value&quot;
        :options=&quot;options&quot;
        :props=&quot;{ expandTrigger: 'hover' }&quot;
        @change=&quot;handleChange&quot;
      &gt;&lt;/el-cascader&gt;
    &lt;/div&gt;
  &lt;/div&gt;
&lt;/template&gt;

&lt;script&gt;
  import { ref } from 'vue'
  export default {
    setup() {
      const value = ref()
      const handleChange = (value) =&gt; {
        console.log(value)
      }
      return {
        value,
        handleChange,
        options: [
          {
            value: 'zhinan',
            label: '指南',
            children: [
              {
                value: 'shejiyuanze',
                label: '设计原则',
                children: [
                  {
                    value: 'yizhi',
                    label: '一致'
                  },
                  {
                    value: 'fankui',
                    label: '反馈'
                  },
                  {
                    value: 'xiaolv',
                    label: '效率'
                  },
                  {
                    value: 'kekong',
                    label: '可控'
                  }
                ]
              },
              {
                value: 'daohang',
                label: '导航',
                children: [
                  {
                    value: 'cexiangdaohang',
                    label: '侧向导航'
                  },
                  {
                    value: 'dingbudaohang',
                    label: '顶部导航'
                  }
                ]
              }
            ]
          },
          {
            value: 'zujian',
            label: '组件',
            children: [
              {
                value: 'basic',
                label: 'Basic',
                children: [
                  {
                    value: 'layout',
                    label: 'Layout 布局'
                  },
                  {
                    value: 'color',
                    label: 'Color 色彩'
                  },
                  {
                    value: 'typography',
                    label: 'Typography 字体'
                  },
                  {
                    value: 'icon',
                    label: 'Icon 图标'
                  },
                  {
                    value: 'button',
                    label: 'Button 按钮'
                  }
                ]
              },
              {
                value: 'form',
                label: 'Form',
                children: [
                  {
                    value: 'radio',
                    label: 'Radio 单选框'
                  },
                  {
                    value: 'checkbox',
                    label: 'Checkbox 多选框'
                  },
                  {
                    value: 'input',
                    label: 'Input 输入框'
                  },
                  {
                    value: 'input-number',
                    label: 'InputNumber 计数器'
                  },
                  {
                    value: 'select',
                    label: 'Select 选择器'
                  },
                  {
                    value: 'cascader',
                    label: 'Cascader 级联选择器'
                  },
                  {
                    value: 'switch',
                    label: 'Switch 开关'
                  },
                  {
                    value: 'slider',
                    label: 'Slider 滑块'
                  },
                  {
                    value: 'time-picker',
                    label: 'TimePicker 时间选择器'
                  },
                  {
                    value: 'date-picker',
                    label: 'DatePicker 日期选择器'
                  },
                  {
                    value: 'datetime-picker',
                    label: 'DateTimePicker 日期时间选择器'
                  },
                  {
                    value: 'upload',
                    label: 'Upload 上传'
                  },
                  {
                    value: 'rate',
                    label: 'Rate 评分'
                  },
                  {
                    value: 'form',
                    label: 'Form 表单'
                  }
                ]
              },
              {
                value: 'data',
                label: 'Data',
                children: [
                  {
                    value: 'table',
                    label: 'Table 表格'
                  },
                  {
                    value: 'tag',
                    label: 'Tag 标签'
                  },
                  {
                    value: 'progress',
                    label: 'Progress 进度条'
                  },
                  {
                    value: 'tree',
                    label: 'Tree 树形控件'
                  },
                  {
                    value: 'pagination',
                    label: 'Pagination 分页'
                  },
                  {
                    value: 'badge',
                    label: 'Badge 标记'
                  }
                ]
              },
              {
                value: 'notice',
                label: 'Notice',
                children: [
                  {
                    value: 'alert',
                    label: 'Alert 警告'
                  },
                  {
                    value: 'loading',
                    label: 'Loading 加载'
                  },
                  {
                    value: 'message',
                    label: 'Message 消息提示'
                  },
                  {
                    value: 'message-box',
                    label: 'MessageBox 弹框'
                  },
                  {
                    value: 'notification',
                    label: 'Notification 通知'
                  }
                ]
              },
              {
                value: 'navigation',
                label: 'Navigation',
                children: [
                  {
                    value: 'menu',
                    label: 'NavMenu 导航菜单'
                  },
                  {
                    value: 'tabs',
                    label: 'Tabs 标签页'
                  },
                  {
                    value: 'breadcrumb',
                    label: 'Breadcrumb 面包屑'
                  },
                  {
                    value: 'dropdown',
                    label: 'Dropdown 下拉菜单'
                  },
                  {
                    value: 'steps',
                    label: 'Steps 步骤条'
                  }
                ]
              },
              {
                value: 'others',
                label: 'Others',
                children: [
                  {
                    value: 'dialog',
                    label: 'Dialog 对话框'
                  },
                  {
                    value: 'tooltip',
                    label: 'Tooltip 文字提示'
                  },
                  {
                    value: 'popover',
                    label: 'Popover 弹出框'
                  },
                  {
                    value: 'card',
                    label: 'Card 卡片'
                  },
                  {
                    value: 'carousel',
                    label: 'Carousel 走马灯'
                  },
                  {
                    value: 'collapse',
                    label: 'Collapse 折叠面板'
                  }
                ]
              }
            ]
          },
          {
            value: 'ziyuan',
            label: '资源',
            children: [
              {
                value: 'axure',
                label: 'Axure Components'
              },
              {
                value: 'sketch',
                label: 'Sketch Templates'
              },
              {
                value: 'jiaohu',
                label: '组件交互文档'
              }
            ]
          }
        ]
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>禁用选项</h3>
<p>通过在数据源中设置 <code>disabled</code> 字段来声明该选项是禁用的</p>
<demo-block>
        <div><p>本例中，<code>options</code>指定的数组中的第一个元素含有<code>disabled: true</code>键值对，因此是禁用的。在默认情况下，Cascader 会检查数据中每一项的<code>disabled</code>字段是否为<code>true</code>，如果你的数据中表示禁用含义的字段名不为<code>disabled</code>，可以通过<code>props.disabled</code>属性来指定（详见下方 API 表格）。当然，<code>value</code>、<code>label</code>和<code>children</code>这三个字段名也可以通过同样的方式指定。</p>
</div>
        <template #source><element-demo1 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;el-cascader :options=&quot;options&quot;&gt;&lt;/el-cascader&gt;
&lt;/template&gt;
&lt;script&gt;
  export default {
    setup() {
      return {
        options: [
          {
            value: 'zhinan',
            label: '指南',
            disabled: true,
            children: [
              {
                value: 'shejiyuanze',
                label: '设计原则',
                children: [
                  {
                    value: 'yizhi',
                    label: '一致'
                  },
                  {
                    value: 'fankui',
                    label: '反馈'
                  },
                  {
                    value: 'xiaolv',
                    label: '效率'
                  },
                  {
                    value: 'kekong',
                    label: '可控'
                  }
                ]
              },
              {
                value: 'daohang',
                label: '导航',
                children: [
                  {
                    value: 'cexiangdaohang',
                    label: '侧向导航'
                  },
                  {
                    value: 'dingbudaohang',
                    label: '顶部导航'
                  }
                ]
              }
            ]
          },
          {
            value: 'zujian',
            label: '组件',
            children: [
              {
                value: 'basic',
                label: 'Basic',
                children: [
                  {
                    value: 'layout',
                    label: 'Layout 布局'
                  },
                  {
                    value: 'color',
                    label: 'Color 色彩'
                  },
                  {
                    value: 'typography',
                    label: 'Typography 字体'
                  },
                  {
                    value: 'icon',
                    label: 'Icon 图标'
                  },
                  {
                    value: 'button',
                    label: 'Button 按钮'
                  }
                ]
              },
              {
                value: 'form',
                label: 'Form',
                children: [
                  {
                    value: 'radio',
                    label: 'Radio 单选框'
                  },
                  {
                    value: 'checkbox',
                    label: 'Checkbox 多选框'
                  },
                  {
                    value: 'input',
                    label: 'Input 输入框'
                  },
                  {
                    value: 'input-number',
                    label: 'InputNumber 计数器'
                  },
                  {
                    value: 'select',
                    label: 'Select 选择器'
                  },
                  {
                    value: 'cascader',
                    label: 'Cascader 级联选择器'
                  },
                  {
                    value: 'switch',
                    label: 'Switch 开关'
                  },
                  {
                    value: 'slider',
                    label: 'Slider 滑块'
                  },
                  {
                    value: 'time-picker',
                    label: 'TimePicker 时间选择器'
                  },
                  {
                    value: 'date-picker',
                    label: 'DatePicker 日期选择器'
                  },
                  {
                    value: 'datetime-picker',
                    label: 'DateTimePicker 日期时间选择器'
                  },
                  {
                    value: 'upload',
                    label: 'Upload 上传'
                  },
                  {
                    value: 'rate',
                    label: 'Rate 评分'
                  },
                  {
                    value: 'form',
                    label: 'Form 表单'
                  }
                ]
              },
              {
                value: 'data',
                label: 'Data',
                children: [
                  {
                    value: 'table',
                    label: 'Table 表格'
                  },
                  {
                    value: 'tag',
                    label: 'Tag 标签'
                  },
                  {
                    value: 'progress',
                    label: 'Progress 进度条'
                  },
                  {
                    value: 'tree',
                    label: 'Tree 树形控件'
                  },
                  {
                    value: 'pagination',
                    label: 'Pagination 分页'
                  },
                  {
                    value: 'badge',
                    label: 'Badge 标记'
                  }
                ]
              },
              {
                value: 'notice',
                label: 'Notice',
                children: [
                  {
                    value: 'alert',
                    label: 'Alert 警告'
                  },
                  {
                    value: 'loading',
                    label: 'Loading 加载'
                  },
                  {
                    value: 'message',
                    label: 'Message 消息提示'
                  },
                  {
                    value: 'message-box',
                    label: 'MessageBox 弹框'
                  },
                  {
                    value: 'notification',
                    label: 'Notification 通知'
                  }
                ]
              },
              {
                value: 'navigation',
                label: 'Navigation',
                children: [
                  {
                    value: 'menu',
                    label: 'NavMenu 导航菜单'
                  },
                  {
                    value: 'tabs',
                    label: 'Tabs 标签页'
                  },
                  {
                    value: 'breadcrumb',
                    label: 'Breadcrumb 面包屑'
                  },
                  {
                    value: 'dropdown',
                    label: 'Dropdown 下拉菜单'
                  },
                  {
                    value: 'steps',
                    label: 'Steps 步骤条'
                  }
                ]
              },
              {
                value: 'others',
                label: 'Others',
                children: [
                  {
                    value: 'dialog',
                    label: 'Dialog 对话框'
                  },
                  {
                    value: 'tooltip',
                    label: 'Tooltip 文字提示'
                  },
                  {
                    value: 'popover',
                    label: 'Popover 弹出框'
                  },
                  {
                    value: 'card',
                    label: 'Card 卡片'
                  },
                  {
                    value: 'carousel',
                    label: 'Carousel 走马灯'
                  },
                  {
                    value: 'collapse',
                    label: 'Collapse 折叠面板'
                  }
                ]
              }
            ]
          },
          {
            value: 'ziyuan',
            label: '资源',
            children: [
              {
                value: 'axure',
                label: 'Axure Components'
              },
              {
                value: 'sketch',
                label: 'Sketch Templates'
              },
              {
                value: 'jiaohu',
                label: '组件交互文档'
              }
            ]
          }
        ]
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>可清空</h3>
<p>通过 <code>clearable</code> 设置输入框可清空</p>
<demo-block>
        
        <template #source><element-demo2 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;el-cascader :options=&quot;options&quot; clearable&gt;&lt;/el-cascader&gt;
&lt;/template&gt;
&lt;script&gt;
  export default {
    setup() {
      return {
        options: [
          {
            value: 'zhinan',
            label: '指南',
            children: [
              {
                value: 'shejiyuanze',
                label: '设计原则',
                children: [
                  {
                    value: 'yizhi',
                    label: '一致'
                  },
                  {
                    value: 'fankui',
                    label: '反馈'
                  },
                  {
                    value: 'xiaolv',
                    label: '效率'
                  },
                  {
                    value: 'kekong',
                    label: '可控'
                  }
                ]
              },
              {
                value: 'daohang',
                label: '导航',
                children: [
                  {
                    value: 'cexiangdaohang',
                    label: '侧向导航'
                  },
                  {
                    value: 'dingbudaohang',
                    label: '顶部导航'
                  }
                ]
              }
            ]
          },
          {
            value: 'zujian',
            label: '组件',
            children: [
              {
                value: 'basic',
                label: 'Basic',
                children: [
                  {
                    value: 'layout',
                    label: 'Layout 布局'
                  },
                  {
                    value: 'color',
                    label: 'Color 色彩'
                  },
                  {
                    value: 'typography',
                    label: 'Typography 字体'
                  },
                  {
                    value: 'icon',
                    label: 'Icon 图标'
                  },
                  {
                    value: 'button',
                    label: 'Button 按钮'
                  }
                ]
              },
              {
                value: 'form',
                label: 'Form',
                children: [
                  {
                    value: 'radio',
                    label: 'Radio 单选框'
                  },
                  {
                    value: 'checkbox',
                    label: 'Checkbox 多选框'
                  },
                  {
                    value: 'input',
                    label: 'Input 输入框'
                  },
                  {
                    value: 'input-number',
                    label: 'InputNumber 计数器'
                  },
                  {
                    value: 'select',
                    label: 'Select 选择器'
                  },
                  {
                    value: 'cascader',
                    label: 'Cascader 级联选择器'
                  },
                  {
                    value: 'switch',
                    label: 'Switch 开关'
                  },
                  {
                    value: 'slider',
                    label: 'Slider 滑块'
                  },
                  {
                    value: 'time-picker',
                    label: 'TimePicker 时间选择器'
                  },
                  {
                    value: 'date-picker',
                    label: 'DatePicker 日期选择器'
                  },
                  {
                    value: 'datetime-picker',
                    label: 'DateTimePicker 日期时间选择器'
                  },
                  {
                    value: 'upload',
                    label: 'Upload 上传'
                  },
                  {
                    value: 'rate',
                    label: 'Rate 评分'
                  },
                  {
                    value: 'form',
                    label: 'Form 表单'
                  }
                ]
              },
              {
                value: 'data',
                label: 'Data',
                children: [
                  {
                    value: 'table',
                    label: 'Table 表格'
                  },
                  {
                    value: 'tag',
                    label: 'Tag 标签'
                  },
                  {
                    value: 'progress',
                    label: 'Progress 进度条'
                  },
                  {
                    value: 'tree',
                    label: 'Tree 树形控件'
                  },
                  {
                    value: 'pagination',
                    label: 'Pagination 分页'
                  },
                  {
                    value: 'badge',
                    label: 'Badge 标记'
                  }
                ]
              },
              {
                value: 'notice',
                label: 'Notice',
                children: [
                  {
                    value: 'alert',
                    label: 'Alert 警告'
                  },
                  {
                    value: 'loading',
                    label: 'Loading 加载'
                  },
                  {
                    value: 'message',
                    label: 'Message 消息提示'
                  },
                  {
                    value: 'message-box',
                    label: 'MessageBox 弹框'
                  },
                  {
                    value: 'notification',
                    label: 'Notification 通知'
                  }
                ]
              },
              {
                value: 'navigation',
                label: 'Navigation',
                children: [
                  {
                    value: 'menu',
                    label: 'NavMenu 导航菜单'
                  },
                  {
                    value: 'tabs',
                    label: 'Tabs 标签页'
                  },
                  {
                    value: 'breadcrumb',
                    label: 'Breadcrumb 面包屑'
                  },
                  {
                    value: 'dropdown',
                    label: 'Dropdown 下拉菜单'
                  },
                  {
                    value: 'steps',
                    label: 'Steps 步骤条'
                  }
                ]
              },
              {
                value: 'others',
                label: 'Others',
                children: [
                  {
                    value: 'dialog',
                    label: 'Dialog 对话框'
                  },
                  {
                    value: 'tooltip',
                    label: 'Tooltip 文字提示'
                  },
                  {
                    value: 'popover',
                    label: 'Popover 弹出框'
                  },
                  {
                    value: 'card',
                    label: 'Card 卡片'
                  },
                  {
                    value: 'carousel',
                    label: 'Carousel 走马灯'
                  },
                  {
                    value: 'collapse',
                    label: 'Collapse 折叠面板'
                  }
                ]
              }
            ]
          },
          {
            value: 'ziyuan',
            label: '资源',
            children: [
              {
                value: 'axure',
                label: 'Axure Components'
              },
              {
                value: 'sketch',
                label: 'Sketch Templates'
              },
              {
                value: 'jiaohu',
                label: '组件交互文档'
              }
            ]
          }
        ]
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>仅显示最后一级</h3>
<p>可以仅在输入框中显示选中项最后一级的标签，而不是选中项所在的完整路径。</p>
<demo-block>
        <div><p>属性<code>show-all-levels</code>定义了是否显示完整的路径，将其赋值为<code>false</code>则仅显示最后一级</p>
</div>
        <template #source><element-demo3 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;el-cascader :options=&quot;options&quot; :show-all-levels=&quot;false&quot;&gt;&lt;/el-cascader&gt;
&lt;/template&gt;
&lt;script&gt;
  export default {
    setup() {
      return {
        options: [
          {
            value: 'zhinan',
            label: '指南',
            children: [
              {
                value: 'shejiyuanze',
                label: '设计原则',
                children: [
                  {
                    value: 'yizhi',
                    label: '一致'
                  },
                  {
                    value: 'fankui',
                    label: '反馈'
                  },
                  {
                    value: 'xiaolv',
                    label: '效率'
                  },
                  {
                    value: 'kekong',
                    label: '可控'
                  }
                ]
              },
              {
                value: 'daohang',
                label: '导航',
                children: [
                  {
                    value: 'cexiangdaohang',
                    label: '侧向导航'
                  },
                  {
                    value: 'dingbudaohang',
                    label: '顶部导航'
                  }
                ]
              }
            ]
          },
          {
            value: 'zujian',
            label: '组件',
            children: [
              {
                value: 'basic',
                label: 'Basic',
                children: [
                  {
                    value: 'layout',
                    label: 'Layout 布局'
                  },
                  {
                    value: 'color',
                    label: 'Color 色彩'
                  },
                  {
                    value: 'typography',
                    label: 'Typography 字体'
                  },
                  {
                    value: 'icon',
                    label: 'Icon 图标'
                  },
                  {
                    value: 'button',
                    label: 'Button 按钮'
                  }
                ]
              },
              {
                value: 'form',
                label: 'Form',
                children: [
                  {
                    value: 'radio',
                    label: 'Radio 单选框'
                  },
                  {
                    value: 'checkbox',
                    label: 'Checkbox 多选框'
                  },
                  {
                    value: 'input',
                    label: 'Input 输入框'
                  },
                  {
                    value: 'input-number',
                    label: 'InputNumber 计数器'
                  },
                  {
                    value: 'select',
                    label: 'Select 选择器'
                  },
                  {
                    value: 'cascader',
                    label: 'Cascader 级联选择器'
                  },
                  {
                    value: 'switch',
                    label: 'Switch 开关'
                  },
                  {
                    value: 'slider',
                    label: 'Slider 滑块'
                  },
                  {
                    value: 'time-picker',
                    label: 'TimePicker 时间选择器'
                  },
                  {
                    value: 'date-picker',
                    label: 'DatePicker 日期选择器'
                  },
                  {
                    value: 'datetime-picker',
                    label: 'DateTimePicker 日期时间选择器'
                  },
                  {
                    value: 'upload',
                    label: 'Upload 上传'
                  },
                  {
                    value: 'rate',
                    label: 'Rate 评分'
                  },
                  {
                    value: 'form',
                    label: 'Form 表单'
                  }
                ]
              },
              {
                value: 'data',
                label: 'Data',
                children: [
                  {
                    value: 'table',
                    label: 'Table 表格'
                  },
                  {
                    value: 'tag',
                    label: 'Tag 标签'
                  },
                  {
                    value: 'progress',
                    label: 'Progress 进度条'
                  },
                  {
                    value: 'tree',
                    label: 'Tree 树形控件'
                  },
                  {
                    value: 'pagination',
                    label: 'Pagination 分页'
                  },
                  {
                    value: 'badge',
                    label: 'Badge 标记'
                  }
                ]
              },
              {
                value: 'notice',
                label: 'Notice',
                children: [
                  {
                    value: 'alert',
                    label: 'Alert 警告'
                  },
                  {
                    value: 'loading',
                    label: 'Loading 加载'
                  },
                  {
                    value: 'message',
                    label: 'Message 消息提示'
                  },
                  {
                    value: 'message-box',
                    label: 'MessageBox 弹框'
                  },
                  {
                    value: 'notification',
                    label: 'Notification 通知'
                  }
                ]
              },
              {
                value: 'navigation',
                label: 'Navigation',
                children: [
                  {
                    value: 'menu',
                    label: 'NavMenu 导航菜单'
                  },
                  {
                    value: 'tabs',
                    label: 'Tabs 标签页'
                  },
                  {
                    value: 'breadcrumb',
                    label: 'Breadcrumb 面包屑'
                  },
                  {
                    value: 'dropdown',
                    label: 'Dropdown 下拉菜单'
                  },
                  {
                    value: 'steps',
                    label: 'Steps 步骤条'
                  }
                ]
              },
              {
                value: 'others',
                label: 'Others',
                children: [
                  {
                    value: 'dialog',
                    label: 'Dialog 对话框'
                  },
                  {
                    value: 'tooltip',
                    label: 'Tooltip 文字提示'
                  },
                  {
                    value: 'popover',
                    label: 'Popover 弹出框'
                  },
                  {
                    value: 'card',
                    label: 'Card 卡片'
                  },
                  {
                    value: 'carousel',
                    label: 'Carousel 走马灯'
                  },
                  {
                    value: 'collapse',
                    label: 'Collapse 折叠面板'
                  }
                ]
              }
            ]
          },
          {
            value: 'ziyuan',
            label: '资源',
            children: [
              {
                value: 'axure',
                label: 'Axure Components'
              },
              {
                value: 'sketch',
                label: 'Sketch Templates'
              },
              {
                value: 'jiaohu',
                label: '组件交互文档'
              }
            ]
          }
        ]
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>多选</h3>
<p>可通过 <code>props.multiple = true</code> 来开启多选模式</p>
<demo-block>
        <div><p>在开启多选模式后，默认情况下会展示所有已选中的选项的 Tag，你可以使用<code>collapse-tags</code>来折叠 Tag</p>
</div>
        <template #source><element-demo4 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;div&gt;
    &lt;div class=&quot;block&quot;&gt;
      &lt;span class=&quot;demonstration&quot;&gt;默认显示所有Tag&lt;/span&gt;
      &lt;el-cascader :options=&quot;options&quot; :props=&quot;props&quot; clearable&gt;&lt;/el-cascader&gt;
    &lt;/div&gt;
    &lt;div class=&quot;block&quot;&gt;
      &lt;span class=&quot;demonstration&quot;&gt;折叠展示Tag&lt;/span&gt;
      &lt;el-cascader
        :options=&quot;options&quot;
        :props=&quot;props&quot;
        collapse-tags
        clearable
      &gt;&lt;/el-cascader&gt;
    &lt;/div&gt;
  &lt;/div&gt;
&lt;/template&gt;

&lt;script&gt;
  export default {
    setup() {
      return {
        props: { multiple: true },
        options: [
          {
            value: 1,
            label: '东南',
            children: [
              {
                value: 2,
                label: '上海',
                children: [
                  { value: 3, label: '普陀' },
                  { value: 4, label: '黄埔' },
                  { value: 5, label: '徐汇' }
                ]
              },
              {
                value: 7,
                label: '江苏',
                children: [
                  { value: 8, label: '南京' },
                  { value: 9, label: '苏州' },
                  { value: 10, label: '无锡' }
                ]
              },
              {
                value: 12,
                label: '浙江',
                children: [
                  { value: 13, label: '杭州' },
                  { value: 14, label: '宁波' },
                  { value: 15, label: '嘉兴' }
                ]
              }
            ]
          },
          {
            value: 17,
            label: '西北',
            children: [
              {
                value: 18,
                label: '陕西',
                children: [
                  { value: 19, label: '西安' },
                  { value: 20, label: '延安' }
                ]
              },
              {
                value: 21,
                label: '新疆维吾尔族自治区',
                children: [
                  { value: 22, label: '乌鲁木齐' },
                  { value: 23, label: '克拉玛依' }
                ]
              }
            ]
          }
        ]
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>选择任意一级选项</h3>
<p>在单选模式下，你只能选择叶子节点；而在多选模式下，勾选父节点真正选中的都是叶子节点。启用该功能后，可让父子节点取消关联，选择任意一级选项。</p>
<demo-block>
        <div><p>可通过 <code>props.checkStrictly = true</code> 来设置父子节点取消选中关联，从而达到选择任意一级选项的目的。</p>
</div>
        <template #source><element-demo5 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;div&gt;
    &lt;div class=&quot;block&quot;&gt;
      &lt;span class=&quot;demonstration&quot;&gt;单选选择任意一级选项&lt;/span&gt;
      &lt;el-cascader
        :options=&quot;options&quot;
        :props=&quot;{ checkStrictly: true }&quot;
        clearable
      &gt;&lt;/el-cascader&gt;
    &lt;/div&gt;
    &lt;div class=&quot;block&quot;&gt;
      &lt;span class=&quot;demonstration&quot;&gt;多选选择任意一级选项&lt;/span&gt;
      &lt;el-cascader
        :options=&quot;options&quot;
        :props=&quot;{ multiple: true, checkStrictly: true }&quot;
        clearable
      &gt;&lt;/el-cascader&gt;
    &lt;/div&gt;
  &lt;/div&gt;
&lt;/template&gt;

&lt;script&gt;
  export default {
    setup() {
      return {
        options: [
          {
            value: 'zhinan',
            label: '指南',
            children: [
              {
                value: 'shejiyuanze',
                label: '设计原则',
                children: [
                  {
                    value: 'yizhi',
                    label: '一致'
                  },
                  {
                    value: 'fankui',
                    label: '反馈'
                  },
                  {
                    value: 'xiaolv',
                    label: '效率'
                  },
                  {
                    value: 'kekong',
                    label: '可控'
                  }
                ]
              },
              {
                value: 'daohang',
                label: '导航',
                children: [
                  {
                    value: 'cexiangdaohang',
                    label: '侧向导航'
                  },
                  {
                    value: 'dingbudaohang',
                    label: '顶部导航'
                  }
                ]
              }
            ]
          },
          {
            value: 'zujian',
            label: '组件',
            children: [
              {
                value: 'basic',
                label: 'Basic',
                children: [
                  {
                    value: 'layout',
                    label: 'Layout 布局'
                  },
                  {
                    value: 'color',
                    label: 'Color 色彩'
                  },
                  {
                    value: 'typography',
                    label: 'Typography 字体'
                  },
                  {
                    value: 'icon',
                    label: 'Icon 图标'
                  },
                  {
                    value: 'button',
                    label: 'Button 按钮'
                  }
                ]
              },
              {
                value: 'form',
                label: 'Form',
                children: [
                  {
                    value: 'radio',
                    label: 'Radio 单选框'
                  },
                  {
                    value: 'checkbox',
                    label: 'Checkbox 多选框'
                  },
                  {
                    value: 'input',
                    label: 'Input 输入框'
                  },
                  {
                    value: 'input-number',
                    label: 'InputNumber 计数器'
                  },
                  {
                    value: 'select',
                    label: 'Select 选择器'
                  },
                  {
                    value: 'cascader',
                    label: 'Cascader 级联选择器'
                  },
                  {
                    value: 'switch',
                    label: 'Switch 开关'
                  },
                  {
                    value: 'slider',
                    label: 'Slider 滑块'
                  },
                  {
                    value: 'time-picker',
                    label: 'TimePicker 时间选择器'
                  },
                  {
                    value: 'date-picker',
                    label: 'DatePicker 日期选择器'
                  },
                  {
                    value: 'datetime-picker',
                    label: 'DateTimePicker 日期时间选择器'
                  },
                  {
                    value: 'upload',
                    label: 'Upload 上传'
                  },
                  {
                    value: 'rate',
                    label: 'Rate 评分'
                  },
                  {
                    value: 'form',
                    label: 'Form 表单'
                  }
                ]
              },
              {
                value: 'data',
                label: 'Data',
                children: [
                  {
                    value: 'table',
                    label: 'Table 表格'
                  },
                  {
                    value: 'tag',
                    label: 'Tag 标签'
                  },
                  {
                    value: 'progress',
                    label: 'Progress 进度条'
                  },
                  {
                    value: 'tree',
                    label: 'Tree 树形控件'
                  },
                  {
                    value: 'pagination',
                    label: 'Pagination 分页'
                  },
                  {
                    value: 'badge',
                    label: 'Badge 标记'
                  }
                ]
              },
              {
                value: 'notice',
                label: 'Notice',
                children: [
                  {
                    value: 'alert',
                    label: 'Alert 警告'
                  },
                  {
                    value: 'loading',
                    label: 'Loading 加载'
                  },
                  {
                    value: 'message',
                    label: 'Message 消息提示'
                  },
                  {
                    value: 'message-box',
                    label: 'MessageBox 弹框'
                  },
                  {
                    value: 'notification',
                    label: 'Notification 通知'
                  }
                ]
              },
              {
                value: 'navigation',
                label: 'Navigation',
                children: [
                  {
                    value: 'menu',
                    label: 'NavMenu 导航菜单'
                  },
                  {
                    value: 'tabs',
                    label: 'Tabs 标签页'
                  },
                  {
                    value: 'breadcrumb',
                    label: 'Breadcrumb 面包屑'
                  },
                  {
                    value: 'dropdown',
                    label: 'Dropdown 下拉菜单'
                  },
                  {
                    value: 'steps',
                    label: 'Steps 步骤条'
                  }
                ]
              },
              {
                value: 'others',
                label: 'Others',
                children: [
                  {
                    value: 'dialog',
                    label: 'Dialog 对话框'
                  },
                  {
                    value: 'tooltip',
                    label: 'Tooltip 文字提示'
                  },
                  {
                    value: 'popover',
                    label: 'Popover 弹出框'
                  },
                  {
                    value: 'card',
                    label: 'Card 卡片'
                  },
                  {
                    value: 'carousel',
                    label: 'Carousel 走马灯'
                  },
                  {
                    value: 'collapse',
                    label: 'Collapse 折叠面板'
                  }
                ]
              }
            ]
          },
          {
            value: 'ziyuan',
            label: '资源',
            children: [
              {
                value: 'axure',
                label: 'Axure Components'
              },
              {
                value: 'sketch',
                label: 'Sketch Templates'
              },
              {
                value: 'jiaohu',
                label: '组件交互文档'
              }
            ]
          }
        ]
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>动态加载</h3>
<p>当选中某一级时，动态加载该级下的选项。</p>
<demo-block>
        <div><p>通过<code>lazy</code>开启动态加载，并通过<code>lazyload</code>来设置加载数据源的方法。<code>lazyload</code>方法有两个参数，第一个参数<code>node</code>为当前点击的节点，第二个<code>resolve</code>为数据加载完成的回调(必须调用)。为了更准确的显示节点的状态，还可以对节点数据添加是否为叶子节点的标志位 (默认字段为<code>leaf</code>，可通过<code>props.leaf</code>修改)，否则会简单的以有无子节点来判断是否为叶子节点。</p>
</div>
        <template #source><element-demo6 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;el-cascader :props=&quot;props&quot;&gt;&lt;/el-cascader&gt;
&lt;/template&gt;
&lt;script&gt;
  let id = 0

  export default {
    setup() {
      const lazyLoad = (node, resolve) =&gt; {
        const { level } = node
        setTimeout(() =&gt; {
          const nodes = Array.from({ length: level + 1 }).map((item) =&gt; ({
            value: ++id,
            label: `选项${id}`,
            leaf: level &gt;= 2
          }))
          // 通过调用resolve将子节点数据返回，通知组件数据加载完成
          resolve(nodes)
        }, 1000)
      }
      return {
        props: {
          lazy: true,
          lazyLoad
        }
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>可搜索</h3>
<p>可以快捷地搜索选项并选择。</p>
<demo-block>
        <div><p>将<code>filterable</code>赋值为<code>true</code>即可打开搜索功能，默认会匹配节点的<code>label</code>或所有父节点的<code>label</code>(由<code>show-all-levels</code>决定)中包含输入值的选项。你也可以用<code>filter-method</code>自定义搜索逻辑，接受一个函数，第一个参数是节点<code>node</code>，第二个参数是搜索关键词<code>keyword</code>，通过返回布尔值表示是否命中。</p>
</div>
        <template #source><element-demo7 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;div&gt;
    &lt;div class=&quot;block&quot;&gt;
      &lt;span class=&quot;demonstration&quot;&gt;单选可搜索&lt;/span&gt;
      &lt;el-cascader
        placeholder=&quot;试试搜索：指南&quot;
        :options=&quot;options&quot;
        filterable
      &gt;&lt;/el-cascader&gt;
    &lt;/div&gt;
    &lt;div class=&quot;block&quot;&gt;
      &lt;span class=&quot;demonstration&quot;&gt;多选可搜索&lt;/span&gt;
      &lt;el-cascader
        placeholder=&quot;试试搜索：指南&quot;
        :options=&quot;options&quot;
        :props=&quot;{ multiple: true }&quot;
        filterable
      &gt;&lt;/el-cascader&gt;
    &lt;/div&gt;
  &lt;/div&gt;
&lt;/template&gt;
&lt;script&gt;
  export default {
    setup() {
      return {
        options: [
          {
            value: 'zhinan',
            label: '指南',
            children: [
              {
                value: 'shejiyuanze',
                label: '设计原则',
                children: [
                  {
                    value: 'yizhi',
                    label: '一致'
                  },
                  {
                    value: 'fankui',
                    label: '反馈'
                  },
                  {
                    value: 'xiaolv',
                    label: '效率'
                  },
                  {
                    value: 'kekong',
                    label: '可控'
                  }
                ]
              },
              {
                value: 'daohang',
                label: '导航',
                children: [
                  {
                    value: 'cexiangdaohang',
                    label: '侧向导航'
                  },
                  {
                    value: 'dingbudaohang',
                    label: '顶部导航'
                  }
                ]
              }
            ]
          },
          {
            value: 'zujian',
            label: '组件',
            children: [
              {
                value: 'basic',
                label: 'Basic',
                children: [
                  {
                    value: 'layout',
                    label: 'Layout 布局'
                  },
                  {
                    value: 'color',
                    label: 'Color 色彩'
                  },
                  {
                    value: 'typography',
                    label: 'Typography 字体'
                  },
                  {
                    value: 'icon',
                    label: 'Icon 图标'
                  },
                  {
                    value: 'button',
                    label: 'Button 按钮'
                  }
                ]
              },
              {
                value: 'form',
                label: 'Form',
                children: [
                  {
                    value: 'radio',
                    label: 'Radio 单选框'
                  },
                  {
                    value: 'checkbox',
                    label: 'Checkbox 多选框'
                  },
                  {
                    value: 'input',
                    label: 'Input 输入框'
                  },
                  {
                    value: 'input-number',
                    label: 'InputNumber 计数器'
                  },
                  {
                    value: 'select',
                    label: 'Select 选择器'
                  },
                  {
                    value: 'cascader',
                    label: 'Cascader 级联选择器'
                  },
                  {
                    value: 'switch',
                    label: 'Switch 开关'
                  },
                  {
                    value: 'slider',
                    label: 'Slider 滑块'
                  },
                  {
                    value: 'time-picker',
                    label: 'TimePicker 时间选择器'
                  },
                  {
                    value: 'date-picker',
                    label: 'DatePicker 日期选择器'
                  },
                  {
                    value: 'datetime-picker',
                    label: 'DateTimePicker 日期时间选择器'
                  },
                  {
                    value: 'upload',
                    label: 'Upload 上传'
                  },
                  {
                    value: 'rate',
                    label: 'Rate 评分'
                  },
                  {
                    value: 'form',
                    label: 'Form 表单'
                  }
                ]
              },
              {
                value: 'data',
                label: 'Data',
                children: [
                  {
                    value: 'table',
                    label: 'Table 表格'
                  },
                  {
                    value: 'tag',
                    label: 'Tag 标签'
                  },
                  {
                    value: 'progress',
                    label: 'Progress 进度条'
                  },
                  {
                    value: 'tree',
                    label: 'Tree 树形控件'
                  },
                  {
                    value: 'pagination',
                    label: 'Pagination 分页'
                  },
                  {
                    value: 'badge',
                    label: 'Badge 标记'
                  }
                ]
              },
              {
                value: 'notice',
                label: 'Notice',
                children: [
                  {
                    value: 'alert',
                    label: 'Alert 警告'
                  },
                  {
                    value: 'loading',
                    label: 'Loading 加载'
                  },
                  {
                    value: 'message',
                    label: 'Message 消息提示'
                  },
                  {
                    value: 'message-box',
                    label: 'MessageBox 弹框'
                  },
                  {
                    value: 'notification',
                    label: 'Notification 通知'
                  }
                ]
              },
              {
                value: 'navigation',
                label: 'Navigation',
                children: [
                  {
                    value: 'menu',
                    label: 'NavMenu 导航菜单'
                  },
                  {
                    value: 'tabs',
                    label: 'Tabs 标签页'
                  },
                  {
                    value: 'breadcrumb',
                    label: 'Breadcrumb 面包屑'
                  },
                  {
                    value: 'dropdown',
                    label: 'Dropdown 下拉菜单'
                  },
                  {
                    value: 'steps',
                    label: 'Steps 步骤条'
                  }
                ]
              },
              {
                value: 'others',
                label: 'Others',
                children: [
                  {
                    value: 'dialog',
                    label: 'Dialog 对话框'
                  },
                  {
                    value: 'tooltip',
                    label: 'Tooltip 文字提示'
                  },
                  {
                    value: 'popover',
                    label: 'Popover 弹出框'
                  },
                  {
                    value: 'card',
                    label: 'Card 卡片'
                  },
                  {
                    value: 'carousel',
                    label: 'Carousel 走马灯'
                  },
                  {
                    value: 'collapse',
                    label: 'Collapse 折叠面板'
                  }
                ]
              }
            ]
          },
          {
            value: 'ziyuan',
            label: '资源',
            children: [
              {
                value: 'axure',
                label: 'Axure Components'
              },
              {
                value: 'sketch',
                label: 'Sketch Templates'
              },
              {
                value: 'jiaohu',
                label: '组件交互文档'
              }
            ]
          }
        ]
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>自定义节点内容</h3>
<p>可以自定义备选项的节点内容</p>
<demo-block>
        <div><p>可以通过<code>v-slot:default</code>对级联选择器的备选项的节点内容进行自定义，v-slot:default 会传入两个字段 <code>node</code> 和 <code>data</code>，分别表示当前节点的 Node 对象和数据。</p>
</div>
        <template #source><element-demo8 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;el-cascader :options=&quot;options&quot;&gt;
    &lt;template v-slot:default=&quot;{ node, data }&quot;&gt;
      &lt;span&gt;{{ data.label }}&lt;/span&gt;
      &lt;span v-if=&quot;!node.isLeaf&quot;&gt; ({{ data.children.length }}) &lt;/span&gt;
    &lt;/template&gt;
  &lt;/el-cascader&gt;
&lt;/template&gt;

&lt;script&gt;
  export default {
    setup() {
      return {
        options: [
          {
            value: 'zhinan',
            label: '指南',
            children: [
              {
                value: 'shejiyuanze',
                label: '设计原则',
                children: [
                  {
                    value: 'yizhi',
                    label: '一致'
                  },
                  {
                    value: 'fankui',
                    label: '反馈'
                  },
                  {
                    value: 'xiaolv',
                    label: '效率'
                  },
                  {
                    value: 'kekong',
                    label: '可控'
                  }
                ]
              },
              {
                value: 'daohang',
                label: '导航',
                children: [
                  {
                    value: 'cexiangdaohang',
                    label: '侧向导航'
                  },
                  {
                    value: 'dingbudaohang',
                    label: '顶部导航'
                  }
                ]
              }
            ]
          },
          {
            value: 'zujian',
            label: '组件',
            children: [
              {
                value: 'basic',
                label: 'Basic',
                children: [
                  {
                    value: 'layout',
                    label: 'Layout 布局'
                  },
                  {
                    value: 'color',
                    label: 'Color 色彩'
                  },
                  {
                    value: 'typography',
                    label: 'Typography 字体'
                  },
                  {
                    value: 'icon',
                    label: 'Icon 图标'
                  },
                  {
                    value: 'button',
                    label: 'Button 按钮'
                  }
                ]
              },
              {
                value: 'form',
                label: 'Form',
                children: [
                  {
                    value: 'radio',
                    label: 'Radio 单选框'
                  },
                  {
                    value: 'checkbox',
                    label: 'Checkbox 多选框'
                  },
                  {
                    value: 'input',
                    label: 'Input 输入框'
                  },
                  {
                    value: 'input-number',
                    label: 'InputNumber 计数器'
                  },
                  {
                    value: 'select',
                    label: 'Select 选择器'
                  },
                  {
                    value: 'cascader',
                    label: 'Cascader 级联选择器'
                  },
                  {
                    value: 'switch',
                    label: 'Switch 开关'
                  },
                  {
                    value: 'slider',
                    label: 'Slider 滑块'
                  },
                  {
                    value: 'time-picker',
                    label: 'TimePicker 时间选择器'
                  },
                  {
                    value: 'date-picker',
                    label: 'DatePicker 日期选择器'
                  },
                  {
                    value: 'datetime-picker',
                    label: 'DateTimePicker 日期时间选择器'
                  },
                  {
                    value: 'upload',
                    label: 'Upload 上传'
                  },
                  {
                    value: 'rate',
                    label: 'Rate 评分'
                  },
                  {
                    value: 'form',
                    label: 'Form 表单'
                  }
                ]
              },
              {
                value: 'data',
                label: 'Data',
                children: [
                  {
                    value: 'table',
                    label: 'Table 表格'
                  },
                  {
                    value: 'tag',
                    label: 'Tag 标签'
                  },
                  {
                    value: 'progress',
                    label: 'Progress 进度条'
                  },
                  {
                    value: 'tree',
                    label: 'Tree 树形控件'
                  },
                  {
                    value: 'pagination',
                    label: 'Pagination 分页'
                  },
                  {
                    value: 'badge',
                    label: 'Badge 标记'
                  }
                ]
              },
              {
                value: 'notice',
                label: 'Notice',
                children: [
                  {
                    value: 'alert',
                    label: 'Alert 警告'
                  },
                  {
                    value: 'loading',
                    label: 'Loading 加载'
                  },
                  {
                    value: 'message',
                    label: 'Message 消息提示'
                  },
                  {
                    value: 'message-box',
                    label: 'MessageBox 弹框'
                  },
                  {
                    value: 'notification',
                    label: 'Notification 通知'
                  }
                ]
              },
              {
                value: 'navigation',
                label: 'Navigation',
                children: [
                  {
                    value: 'menu',
                    label: 'NavMenu 导航菜单'
                  },
                  {
                    value: 'tabs',
                    label: 'Tabs 标签页'
                  },
                  {
                    value: 'breadcrumb',
                    label: 'Breadcrumb 面包屑'
                  },
                  {
                    value: 'dropdown',
                    label: 'Dropdown 下拉菜单'
                  },
                  {
                    value: 'steps',
                    label: 'Steps 步骤条'
                  }
                ]
              },
              {
                value: 'others',
                label: 'Others',
                children: [
                  {
                    value: 'dialog',
                    label: 'Dialog 对话框'
                  },
                  {
                    value: 'tooltip',
                    label: 'Tooltip 文字提示'
                  },
                  {
                    value: 'popover',
                    label: 'Popover 弹出框'
                  },
                  {
                    value: 'card',
                    label: 'Card 卡片'
                  },
                  {
                    value: 'carousel',
                    label: 'Carousel 走马灯'
                  },
                  {
                    value: 'collapse',
                    label: 'Collapse 折叠面板'
                  }
                ]
              }
            ]
          },
          {
            value: 'ziyuan',
            label: '资源',
            children: [
              {
                value: 'axure',
                label: 'Axure Components'
              },
              {
                value: 'sketch',
                label: 'Sketch Templates'
              },
              {
                value: 'jiaohu',
                label: '组件交互文档'
              }
            ]
          }
        ]
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>级联面板</h3>
<p>级联面板是级联选择器的核心组件，与级联选择器一样，有单选、多选、动态加载等多种功能。</p>
<demo-block>
        <div><p>和级联选择器一样，通过<code>options</code>来指定选项，也可通过<code>props</code>来设置多选、动态加载等功能，具体详情见下方 API 表格。</p>
</div>
        <template #source><element-demo9 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;div&gt;
    &lt;el-cascader-panel :options=&quot;options&quot;&gt;&lt;/el-cascader-panel&gt;
  &lt;/div&gt;
&lt;/template&gt;

&lt;script&gt;
  export default {
    setup() {
      return {
        options: [
          {
            value: 'zhinan',
            label: '指南',
            children: [
              {
                value: 'shejiyuanze',
                label: '设计原则',
                children: [
                  {
                    value: 'yizhi',
                    label: '一致'
                  },
                  {
                    value: 'fankui',
                    label: '反馈'
                  },
                  {
                    value: 'xiaolv',
                    label: '效率'
                  },
                  {
                    value: 'kekong',
                    label: '可控'
                  }
                ]
              },
              {
                value: 'daohang',
                label: '导航',
                children: [
                  {
                    value: 'cexiangdaohang',
                    label: '侧向导航'
                  },
                  {
                    value: 'dingbudaohang',
                    label: '顶部导航'
                  }
                ]
              }
            ]
          },
          {
            value: 'zujian',
            label: '组件',
            children: [
              {
                value: 'basic',
                label: 'Basic',
                children: [
                  {
                    value: 'layout',
                    label: 'Layout 布局'
                  },
                  {
                    value: 'color',
                    label: 'Color 色彩'
                  },
                  {
                    value: 'typography',
                    label: 'Typography 字体'
                  },
                  {
                    value: 'icon',
                    label: 'Icon 图标'
                  },
                  {
                    value: 'button',
                    label: 'Button 按钮'
                  }
                ]
              },
              {
                value: 'form',
                label: 'Form',
                children: [
                  {
                    value: 'radio',
                    label: 'Radio 单选框'
                  },
                  {
                    value: 'checkbox',
                    label: 'Checkbox 多选框'
                  },
                  {
                    value: 'input',
                    label: 'Input 输入框'
                  },
                  {
                    value: 'input-number',
                    label: 'InputNumber 计数器'
                  },
                  {
                    value: 'select',
                    label: 'Select 选择器'
                  },
                  {
                    value: 'cascader',
                    label: 'Cascader 级联选择器'
                  },
                  {
                    value: 'switch',
                    label: 'Switch 开关'
                  },
                  {
                    value: 'slider',
                    label: 'Slider 滑块'
                  },
                  {
                    value: 'time-picker',
                    label: 'TimePicker 时间选择器'
                  },
                  {
                    value: 'date-picker',
                    label: 'DatePicker 日期选择器'
                  },
                  {
                    value: 'datetime-picker',
                    label: 'DateTimePicker 日期时间选择器'
                  },
                  {
                    value: 'upload',
                    label: 'Upload 上传'
                  },
                  {
                    value: 'rate',
                    label: 'Rate 评分'
                  },
                  {
                    value: 'form',
                    label: 'Form 表单'
                  }
                ]
              },
              {
                value: 'data',
                label: 'Data',
                children: [
                  {
                    value: 'table',
                    label: 'Table 表格'
                  },
                  {
                    value: 'tag',
                    label: 'Tag 标签'
                  },
                  {
                    value: 'progress',
                    label: 'Progress 进度条'
                  },
                  {
                    value: 'tree',
                    label: 'Tree 树形控件'
                  },
                  {
                    value: 'pagination',
                    label: 'Pagination 分页'
                  },
                  {
                    value: 'badge',
                    label: 'Badge 标记'
                  }
                ]
              },
              {
                value: 'notice',
                label: 'Notice',
                children: [
                  {
                    value: 'alert',
                    label: 'Alert 警告'
                  },
                  {
                    value: 'loading',
                    label: 'Loading 加载'
                  },
                  {
                    value: 'message',
                    label: 'Message 消息提示'
                  },
                  {
                    value: 'message-box',
                    label: 'MessageBox 弹框'
                  },
                  {
                    value: 'notification',
                    label: 'Notification 通知'
                  }
                ]
              },
              {
                value: 'navigation',
                label: 'Navigation',
                children: [
                  {
                    value: 'menu',
                    label: 'NavMenu 导航菜单'
                  },
                  {
                    value: 'tabs',
                    label: 'Tabs 标签页'
                  },
                  {
                    value: 'breadcrumb',
                    label: 'Breadcrumb 面包屑'
                  },
                  {
                    value: 'dropdown',
                    label: 'Dropdown 下拉菜单'
                  },
                  {
                    value: 'steps',
                    label: 'Steps 步骤条'
                  }
                ]
              },
              {
                value: 'others',
                label: 'Others',
                children: [
                  {
                    value: 'dialog',
                    label: 'Dialog 对话框'
                  },
                  {
                    value: 'tooltip',
                    label: 'Tooltip 文字提示'
                  },
                  {
                    value: 'popover',
                    label: 'Popover 弹出框'
                  },
                  {
                    value: 'card',
                    label: 'Card 卡片'
                  },
                  {
                    value: 'carousel',
                    label: 'Carousel 走马灯'
                  },
                  {
                    value: 'collapse',
                    label: 'Collapse 折叠面板'
                  }
                ]
              }
            ]
          },
          {
            value: 'ziyuan',
            label: '资源',
            children: [
              {
                value: 'axure',
                label: 'Axure Components'
              },
              {
                value: 'sketch',
                label: 'Sketch Templates'
              },
              {
                value: 'jiaohu',
                label: '组件交互文档'
              }
            ]
          }
        ]
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>Cascader Attributes</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>modelValue / v-model</td>
<td>选中项绑定值</td>
<td>-</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>options</td>
<td>可选项数据源，键名可通过 <code>Props</code> 属性配置</td>
<td>array</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>props</td>
<td>配置选项，具体见下表</td>
<td>object</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>size</td>
<td>尺寸</td>
<td>string</td>
<td>medium / small / mini</td>
<td>—</td>
</tr>
<tr>
<td>placeholder</td>
<td>输入框占位文本</td>
<td>string</td>
<td>—</td>
<td>请选择</td>
</tr>
<tr>
<td>disabled</td>
<td>是否禁用</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>clearable</td>
<td>是否支持清空选项</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>show-all-levels</td>
<td>输入框中是否显示选中值的完整路径</td>
<td>boolean</td>
<td>—</td>
<td>true</td>
</tr>
<tr>
<td>collapse-tags</td>
<td>多选模式下是否折叠 Tag</td>
<td>boolean</td>
<td>-</td>
<td>false</td>
</tr>
<tr>
<td>separator</td>
<td>选项分隔符</td>
<td>string</td>
<td>—</td>
<td>斜杠' / '</td>
</tr>
<tr>
<td>filterable</td>
<td>是否可搜索选项</td>
<td>boolean</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>filter-method</td>
<td>自定义搜索逻辑，第一个参数是节点<code>node</code>，第二个参数是搜索关键词<code>keyword</code>，通过返回布尔值表示是否命中</td>
<td>function(node, keyword)</td>
<td>-</td>
<td>-</td>
</tr>
<tr>
<td>debounce</td>
<td>搜索关键词输入的去抖延迟，毫秒</td>
<td>number</td>
<td>—</td>
<td>300</td>
</tr>
<tr>
<td>before-filter</td>
<td>筛选之前的钩子，参数为输入的值，若返回 false 或者返回 Promise 且被 reject，则停止筛选</td>
<td>function(value)</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>popper-class</td>
<td>自定义浮层类名</td>
<td>string</td>
<td>—</td>
<td>—</td>
</tr>
</tbody>
</table>
<h3>Cascader Events</h3>
<table>
<thead>
<tr>
<th>事件名称</th>
<th>说明</th>
<th>回调参数</th>
</tr>
</thead>
<tbody>
<tr>
<td>change</td>
<td>当选中节点变化时触发</td>
<td>选中节点的值</td>
</tr>
<tr>
<td>expand-change</td>
<td>当展开节点发生变化时触发</td>
<td>各父级选项值组成的数组</td>
</tr>
<tr>
<td>blur</td>
<td>当失去焦点时触发</td>
<td>(event: Event)</td>
</tr>
<tr>
<td>focus</td>
<td>当获得焦点时触发</td>
<td>(event: Event)</td>
</tr>
<tr>
<td>visible-change</td>
<td>下拉框出现/隐藏时触发</td>
<td>出现则为 true，隐藏则为 false</td>
</tr>
<tr>
<td>remove-tag</td>
<td>在多选模式下，移除 Tag 时触发</td>
<td>移除的 Tag 对应的节点的值</td>
</tr>
</tbody>
</table>
<h3>Cascader Methods</h3>
<table>
<thead>
<tr>
<th>方法名</th>
<th>说明</th>
<th>参数</th>
</tr>
</thead>
<tbody>
<tr>
<td>getCheckedNodes</td>
<td>获取选中的节点</td>
<td>(leafOnly) 是否只是叶子节点，默认值为 <code>false</code></td>
</tr>
</tbody>
</table>
<h3>Cascader Slots</h3>
<table>
<thead>
<tr>
<th>名称</th>
<th>说明</th>
</tr>
</thead>
<tbody>
<tr>
<td>-</td>
<td>自定义备选项的节点内容，参数为 { node, data }，分别为当前节点的 Node 对象和数据</td>
</tr>
<tr>
<td>empty</td>
<td>无匹配选项时的内容</td>
</tr>
</tbody>
</table>
<h3>CascaderPanel Attributes</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>modelValue / v-model</td>
<td>选中项绑定值</td>
<td>-</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>options</td>
<td>可选项数据源，键名可通过 <code>Props</code> 属性配置</td>
<td>array</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>props</td>
<td>配置选项，具体见下表</td>
<td>object</td>
<td>—</td>
<td>—</td>
</tr>
</tbody>
</table>
<h3>CascaderPanel Events</h3>
<table>
<thead>
<tr>
<th>事件名称</th>
<th>说明</th>
<th>回调参数</th>
</tr>
</thead>
<tbody>
<tr>
<td>change</td>
<td>当选中节点变化时触发</td>
<td>选中节点的值</td>
</tr>
<tr>
<td>expand-change</td>
<td>当展开节点发生变化时触发</td>
<td>各父级选项值组成的数组</td>
</tr>
</tbody>
</table>
<h3>CascaderPanel Methods</h3>
<table>
<thead>
<tr>
<th>方法名</th>
<th>说明</th>
<th>参数</th>
</tr>
</thead>
<tbody>
<tr>
<td>getCheckedNodes</td>
<td>获取选中的节点数组</td>
<td>(leafOnly) 是否只是叶子节点，默认值为 <code>false</code></td>
</tr>
<tr>
<td>clearCheckedNodes</td>
<td>清空选中的节点</td>
<td>-</td>
</tr>
</tbody>
</table>
<h3>CascaderPanel Slots</h3>
<table>
<thead>
<tr>
<th>名称</th>
<th>说明</th>
</tr>
</thead>
<tbody>
<tr>
<td>-</td>
<td>自定义备选项的节点内容，参数为 { node, data }，分别为当前节点的 Node 对象和数据</td>
</tr>
</tbody>
</table>
<h3>Props</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>expandTrigger</td>
<td>次级菜单的展开方式</td>
<td>string</td>
<td>click / hover</td>
<td>'click'</td>
</tr>
<tr>
<td>multiple</td>
<td>是否多选</td>
<td>boolean</td>
<td>-</td>
<td>false</td>
</tr>
<tr>
<td>checkStrictly</td>
<td>是否严格的遵守父子节点不互相关联</td>
<td>boolean</td>
<td>-</td>
<td>false</td>
</tr>
<tr>
<td>emitPath</td>
<td>在选中节点改变时，是否返回由该节点所在的各级菜单的值所组成的数组，若设置 false，则只返回该节点的值</td>
<td>boolean</td>
<td>-</td>
<td>true</td>
</tr>
<tr>
<td>lazy</td>
<td>是否动态加载子节点，需与 lazyLoad 方法结合使用</td>
<td>boolean</td>
<td>-</td>
<td>false</td>
</tr>
<tr>
<td>lazyLoad</td>
<td>加载动态数据的方法，仅在 lazy 为 true 时有效</td>
<td>function(node, resolve)，<code>node</code>为当前点击的节点，<code>resolve</code>为数据加载完成的回调(必须调用)</td>
<td>-</td>
<td>-</td>
</tr>
<tr>
<td>value</td>
<td>指定选项的值为选项对象的某个属性值</td>
<td>string</td>
<td>—</td>
<td>'value'</td>
</tr>
<tr>
<td>label</td>
<td>指定选项标签为选项对象的某个属性值</td>
<td>string</td>
<td>—</td>
<td>'label'</td>
</tr>
<tr>
<td>children</td>
<td>指定选项的子选项为选项对象的某个属性值</td>
<td>string</td>
<td>—</td>
<td>'children'</td>
</tr>
<tr>
<td>disabled</td>
<td>指定选项的禁用为选项对象的某个属性值</td>
<td>string</td>
<td>—</td>
<td>'disabled'</td>
</tr>
<tr>
<td>leaf</td>
<td>指定选项的叶子节点的标志位为选项对象的某个属性值</td>
<td>string</td>
<td>—</td>
<td>'leaf'</td>
</tr>
</tbody>
</table>

      </section>
    </template>
    <script>
      import hljs from 'highlight.js'
      import * as Vue from "vue"
      export default {
        name: 'component-doc',
        components: {
          "element-demo0": (function() {
    
    const { createVNode: _createVNode, resolveComponent: _resolveComponent, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_cascader = _resolveComponent("el-cascader")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode("div", { class: "block" }, [
      _createVNode("span", { class: "demonstration" }, "默认 click 触发子菜单"),
      _createVNode(_component_el_cascader, {
        modelValue: _ctx.value,
        "onUpdate:modelValue": $event => (_ctx.value = $event),
        options: _ctx.options,
        onChange: _ctx.handleChange
      }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "onChange"])
    ]),
    _createVNode("div", { class: "block" }, [
      _createVNode("span", { class: "demonstration" }, "hover 触发子菜单"),
      _createVNode(_component_el_cascader, {
        modelValue: _ctx.value,
        "onUpdate:modelValue": $event => (_ctx.value = $event),
        options: _ctx.options,
        props: { expandTrigger: 'hover' },
        onChange: _ctx.handleChange
      }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "onChange"])
    ])
  ]))
}
  
    
        const { ref } = Vue
        
  const democomponentExport = {
    setup() {
      const value = ref()
      const handleChange = (value) => {
        console.log(value)
      }
      return {
        value,
        handleChange,
        options: [
          {
            value: 'zhinan',
            label: '指南',
            children: [
              {
                value: 'shejiyuanze',
                label: '设计原则',
                children: [
                  {
                    value: 'yizhi',
                    label: '一致'
                  },
                  {
                    value: 'fankui',
                    label: '反馈'
                  },
                  {
                    value: 'xiaolv',
                    label: '效率'
                  },
                  {
                    value: 'kekong',
                    label: '可控'
                  }
                ]
              },
              {
                value: 'daohang',
                label: '导航',
                children: [
                  {
                    value: 'cexiangdaohang',
                    label: '侧向导航'
                  },
                  {
                    value: 'dingbudaohang',
                    label: '顶部导航'
                  }
                ]
              }
            ]
          },
          {
            value: 'zujian',
            label: '组件',
            children: [
              {
                value: 'basic',
                label: 'Basic',
                children: [
                  {
                    value: 'layout',
                    label: 'Layout 布局'
                  },
                  {
                    value: 'color',
                    label: 'Color 色彩'
                  },
                  {
                    value: 'typography',
                    label: 'Typography 字体'
                  },
                  {
                    value: 'icon',
                    label: 'Icon 图标'
                  },
                  {
                    value: 'button',
                    label: 'Button 按钮'
                  }
                ]
              },
              {
                value: 'form',
                label: 'Form',
                children: [
                  {
                    value: 'radio',
                    label: 'Radio 单选框'
                  },
                  {
                    value: 'checkbox',
                    label: 'Checkbox 多选框'
                  },
                  {
                    value: 'input',
                    label: 'Input 输入框'
                  },
                  {
                    value: 'input-number',
                    label: 'InputNumber 计数器'
                  },
                  {
                    value: 'select',
                    label: 'Select 选择器'
                  },
                  {
                    value: 'cascader',
                    label: 'Cascader 级联选择器'
                  },
                  {
                    value: 'switch',
                    label: 'Switch 开关'
                  },
                  {
                    value: 'slider',
                    label: 'Slider 滑块'
                  },
                  {
                    value: 'time-picker',
                    label: 'TimePicker 时间选择器'
                  },
                  {
                    value: 'date-picker',
                    label: 'DatePicker 日期选择器'
                  },
                  {
                    value: 'datetime-picker',
                    label: 'DateTimePicker 日期时间选择器'
                  },
                  {
                    value: 'upload',
                    label: 'Upload 上传'
                  },
                  {
                    value: 'rate',
                    label: 'Rate 评分'
                  },
                  {
                    value: 'form',
                    label: 'Form 表单'
                  }
                ]
              },
              {
                value: 'data',
                label: 'Data',
                children: [
                  {
                    value: 'table',
                    label: 'Table 表格'
                  },
                  {
                    value: 'tag',
                    label: 'Tag 标签'
                  },
                  {
                    value: 'progress',
                    label: 'Progress 进度条'
                  },
                  {
                    value: 'tree',
                    label: 'Tree 树形控件'
                  },
                  {
                    value: 'pagination',
                    label: 'Pagination 分页'
                  },
                  {
                    value: 'badge',
                    label: 'Badge 标记'
                  }
                ]
              },
              {
                value: 'notice',
                label: 'Notice',
                children: [
                  {
                    value: 'alert',
                    label: 'Alert 警告'
                  },
                  {
                    value: 'loading',
                    label: 'Loading 加载'
                  },
                  {
                    value: 'message',
                    label: 'Message 消息提示'
                  },
                  {
                    value: 'message-box',
                    label: 'MessageBox 弹框'
                  },
                  {
                    value: 'notification',
                    label: 'Notification 通知'
                  }
                ]
              },
              {
                value: 'navigation',
                label: 'Navigation',
                children: [
                  {
                    value: 'menu',
                    label: 'NavMenu 导航菜单'
                  },
                  {
                    value: 'tabs',
                    label: 'Tabs 标签页'
                  },
                  {
                    value: 'breadcrumb',
                    label: 'Breadcrumb 面包屑'
                  },
                  {
                    value: 'dropdown',
                    label: 'Dropdown 下拉菜单'
                  },
                  {
                    value: 'steps',
                    label: 'Steps 步骤条'
                  }
                ]
              },
              {
                value: 'others',
                label: 'Others',
                children: [
                  {
                    value: 'dialog',
                    label: 'Dialog 对话框'
                  },
                  {
                    value: 'tooltip',
                    label: 'Tooltip 文字提示'
                  },
                  {
                    value: 'popover',
                    label: 'Popover 弹出框'
                  },
                  {
                    value: 'card',
                    label: 'Card 卡片'
                  },
                  {
                    value: 'carousel',
                    label: 'Carousel 走马灯'
                  },
                  {
                    value: 'collapse',
                    label: 'Collapse 折叠面板'
                  }
                ]
              }
            ]
          },
          {
            value: 'ziyuan',
            label: '资源',
            children: [
              {
                value: 'axure',
                label: 'Axure Components'
              },
              {
                value: 'sketch',
                label: 'Sketch Templates'
              },
              {
                value: 'jiaohu',
                label: '组件交互文档'
              }
            ]
          }
        ]
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo1": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_cascader = _resolveComponent("el-cascader")

  return (_openBlock(), _createBlock(_component_el_cascader, { options: _ctx.options }, null, 8, ["options"]))
}
  
    const democomponentExport = {
    setup() {
      return {
        options: [
          {
            value: 'zhinan',
            label: '指南',
            disabled: true,
            children: [
              {
                value: 'shejiyuanze',
                label: '设计原则',
                children: [
                  {
                    value: 'yizhi',
                    label: '一致'
                  },
                  {
                    value: 'fankui',
                    label: '反馈'
                  },
                  {
                    value: 'xiaolv',
                    label: '效率'
                  },
                  {
                    value: 'kekong',
                    label: '可控'
                  }
                ]
              },
              {
                value: 'daohang',
                label: '导航',
                children: [
                  {
                    value: 'cexiangdaohang',
                    label: '侧向导航'
                  },
                  {
                    value: 'dingbudaohang',
                    label: '顶部导航'
                  }
                ]
              }
            ]
          },
          {
            value: 'zujian',
            label: '组件',
            children: [
              {
                value: 'basic',
                label: 'Basic',
                children: [
                  {
                    value: 'layout',
                    label: 'Layout 布局'
                  },
                  {
                    value: 'color',
                    label: 'Color 色彩'
                  },
                  {
                    value: 'typography',
                    label: 'Typography 字体'
                  },
                  {
                    value: 'icon',
                    label: 'Icon 图标'
                  },
                  {
                    value: 'button',
                    label: 'Button 按钮'
                  }
                ]
              },
              {
                value: 'form',
                label: 'Form',
                children: [
                  {
                    value: 'radio',
                    label: 'Radio 单选框'
                  },
                  {
                    value: 'checkbox',
                    label: 'Checkbox 多选框'
                  },
                  {
                    value: 'input',
                    label: 'Input 输入框'
                  },
                  {
                    value: 'input-number',
                    label: 'InputNumber 计数器'
                  },
                  {
                    value: 'select',
                    label: 'Select 选择器'
                  },
                  {
                    value: 'cascader',
                    label: 'Cascader 级联选择器'
                  },
                  {
                    value: 'switch',
                    label: 'Switch 开关'
                  },
                  {
                    value: 'slider',
                    label: 'Slider 滑块'
                  },
                  {
                    value: 'time-picker',
                    label: 'TimePicker 时间选择器'
                  },
                  {
                    value: 'date-picker',
                    label: 'DatePicker 日期选择器'
                  },
                  {
                    value: 'datetime-picker',
                    label: 'DateTimePicker 日期时间选择器'
                  },
                  {
                    value: 'upload',
                    label: 'Upload 上传'
                  },
                  {
                    value: 'rate',
                    label: 'Rate 评分'
                  },
                  {
                    value: 'form',
                    label: 'Form 表单'
                  }
                ]
              },
              {
                value: 'data',
                label: 'Data',
                children: [
                  {
                    value: 'table',
                    label: 'Table 表格'
                  },
                  {
                    value: 'tag',
                    label: 'Tag 标签'
                  },
                  {
                    value: 'progress',
                    label: 'Progress 进度条'
                  },
                  {
                    value: 'tree',
                    label: 'Tree 树形控件'
                  },
                  {
                    value: 'pagination',
                    label: 'Pagination 分页'
                  },
                  {
                    value: 'badge',
                    label: 'Badge 标记'
                  }
                ]
              },
              {
                value: 'notice',
                label: 'Notice',
                children: [
                  {
                    value: 'alert',
                    label: 'Alert 警告'
                  },
                  {
                    value: 'loading',
                    label: 'Loading 加载'
                  },
                  {
                    value: 'message',
                    label: 'Message 消息提示'
                  },
                  {
                    value: 'message-box',
                    label: 'MessageBox 弹框'
                  },
                  {
                    value: 'notification',
                    label: 'Notification 通知'
                  }
                ]
              },
              {
                value: 'navigation',
                label: 'Navigation',
                children: [
                  {
                    value: 'menu',
                    label: 'NavMenu 导航菜单'
                  },
                  {
                    value: 'tabs',
                    label: 'Tabs 标签页'
                  },
                  {
                    value: 'breadcrumb',
                    label: 'Breadcrumb 面包屑'
                  },
                  {
                    value: 'dropdown',
                    label: 'Dropdown 下拉菜单'
                  },
                  {
                    value: 'steps',
                    label: 'Steps 步骤条'
                  }
                ]
              },
              {
                value: 'others',
                label: 'Others',
                children: [
                  {
                    value: 'dialog',
                    label: 'Dialog 对话框'
                  },
                  {
                    value: 'tooltip',
                    label: 'Tooltip 文字提示'
                  },
                  {
                    value: 'popover',
                    label: 'Popover 弹出框'
                  },
                  {
                    value: 'card',
                    label: 'Card 卡片'
                  },
                  {
                    value: 'carousel',
                    label: 'Carousel 走马灯'
                  },
                  {
                    value: 'collapse',
                    label: 'Collapse 折叠面板'
                  }
                ]
              }
            ]
          },
          {
            value: 'ziyuan',
            label: '资源',
            children: [
              {
                value: 'axure',
                label: 'Axure Components'
              },
              {
                value: 'sketch',
                label: 'Sketch Templates'
              },
              {
                value: 'jiaohu',
                label: '组件交互文档'
              }
            ]
          }
        ]
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo2": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_cascader = _resolveComponent("el-cascader")

  return (_openBlock(), _createBlock(_component_el_cascader, {
    options: _ctx.options,
    clearable: ""
  }, null, 8, ["options"]))
}
  
    const democomponentExport = {
    setup() {
      return {
        options: [
          {
            value: 'zhinan',
            label: '指南',
            children: [
              {
                value: 'shejiyuanze',
                label: '设计原则',
                children: [
                  {
                    value: 'yizhi',
                    label: '一致'
                  },
                  {
                    value: 'fankui',
                    label: '反馈'
                  },
                  {
                    value: 'xiaolv',
                    label: '效率'
                  },
                  {
                    value: 'kekong',
                    label: '可控'
                  }
                ]
              },
              {
                value: 'daohang',
                label: '导航',
                children: [
                  {
                    value: 'cexiangdaohang',
                    label: '侧向导航'
                  },
                  {
                    value: 'dingbudaohang',
                    label: '顶部导航'
                  }
                ]
              }
            ]
          },
          {
            value: 'zujian',
            label: '组件',
            children: [
              {
                value: 'basic',
                label: 'Basic',
                children: [
                  {
                    value: 'layout',
                    label: 'Layout 布局'
                  },
                  {
                    value: 'color',
                    label: 'Color 色彩'
                  },
                  {
                    value: 'typography',
                    label: 'Typography 字体'
                  },
                  {
                    value: 'icon',
                    label: 'Icon 图标'
                  },
                  {
                    value: 'button',
                    label: 'Button 按钮'
                  }
                ]
              },
              {
                value: 'form',
                label: 'Form',
                children: [
                  {
                    value: 'radio',
                    label: 'Radio 单选框'
                  },
                  {
                    value: 'checkbox',
                    label: 'Checkbox 多选框'
                  },
                  {
                    value: 'input',
                    label: 'Input 输入框'
                  },
                  {
                    value: 'input-number',
                    label: 'InputNumber 计数器'
                  },
                  {
                    value: 'select',
                    label: 'Select 选择器'
                  },
                  {
                    value: 'cascader',
                    label: 'Cascader 级联选择器'
                  },
                  {
                    value: 'switch',
                    label: 'Switch 开关'
                  },
                  {
                    value: 'slider',
                    label: 'Slider 滑块'
                  },
                  {
                    value: 'time-picker',
                    label: 'TimePicker 时间选择器'
                  },
                  {
                    value: 'date-picker',
                    label: 'DatePicker 日期选择器'
                  },
                  {
                    value: 'datetime-picker',
                    label: 'DateTimePicker 日期时间选择器'
                  },
                  {
                    value: 'upload',
                    label: 'Upload 上传'
                  },
                  {
                    value: 'rate',
                    label: 'Rate 评分'
                  },
                  {
                    value: 'form',
                    label: 'Form 表单'
                  }
                ]
              },
              {
                value: 'data',
                label: 'Data',
                children: [
                  {
                    value: 'table',
                    label: 'Table 表格'
                  },
                  {
                    value: 'tag',
                    label: 'Tag 标签'
                  },
                  {
                    value: 'progress',
                    label: 'Progress 进度条'
                  },
                  {
                    value: 'tree',
                    label: 'Tree 树形控件'
                  },
                  {
                    value: 'pagination',
                    label: 'Pagination 分页'
                  },
                  {
                    value: 'badge',
                    label: 'Badge 标记'
                  }
                ]
              },
              {
                value: 'notice',
                label: 'Notice',
                children: [
                  {
                    value: 'alert',
                    label: 'Alert 警告'
                  },
                  {
                    value: 'loading',
                    label: 'Loading 加载'
                  },
                  {
                    value: 'message',
                    label: 'Message 消息提示'
                  },
                  {
                    value: 'message-box',
                    label: 'MessageBox 弹框'
                  },
                  {
                    value: 'notification',
                    label: 'Notification 通知'
                  }
                ]
              },
              {
                value: 'navigation',
                label: 'Navigation',
                children: [
                  {
                    value: 'menu',
                    label: 'NavMenu 导航菜单'
                  },
                  {
                    value: 'tabs',
                    label: 'Tabs 标签页'
                  },
                  {
                    value: 'breadcrumb',
                    label: 'Breadcrumb 面包屑'
                  },
                  {
                    value: 'dropdown',
                    label: 'Dropdown 下拉菜单'
                  },
                  {
                    value: 'steps',
                    label: 'Steps 步骤条'
                  }
                ]
              },
              {
                value: 'others',
                label: 'Others',
                children: [
                  {
                    value: 'dialog',
                    label: 'Dialog 对话框'
                  },
                  {
                    value: 'tooltip',
                    label: 'Tooltip 文字提示'
                  },
                  {
                    value: 'popover',
                    label: 'Popover 弹出框'
                  },
                  {
                    value: 'card',
                    label: 'Card 卡片'
                  },
                  {
                    value: 'carousel',
                    label: 'Carousel 走马灯'
                  },
                  {
                    value: 'collapse',
                    label: 'Collapse 折叠面板'
                  }
                ]
              }
            ]
          },
          {
            value: 'ziyuan',
            label: '资源',
            children: [
              {
                value: 'axure',
                label: 'Axure Components'
              },
              {
                value: 'sketch',
                label: 'Sketch Templates'
              },
              {
                value: 'jiaohu',
                label: '组件交互文档'
              }
            ]
          }
        ]
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo3": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_cascader = _resolveComponent("el-cascader")

  return (_openBlock(), _createBlock(_component_el_cascader, {
    options: _ctx.options,
    "show-all-levels": false
  }, null, 8, ["options"]))
}
  
    const democomponentExport = {
    setup() {
      return {
        options: [
          {
            value: 'zhinan',
            label: '指南',
            children: [
              {
                value: 'shejiyuanze',
                label: '设计原则',
                children: [
                  {
                    value: 'yizhi',
                    label: '一致'
                  },
                  {
                    value: 'fankui',
                    label: '反馈'
                  },
                  {
                    value: 'xiaolv',
                    label: '效率'
                  },
                  {
                    value: 'kekong',
                    label: '可控'
                  }
                ]
              },
              {
                value: 'daohang',
                label: '导航',
                children: [
                  {
                    value: 'cexiangdaohang',
                    label: '侧向导航'
                  },
                  {
                    value: 'dingbudaohang',
                    label: '顶部导航'
                  }
                ]
              }
            ]
          },
          {
            value: 'zujian',
            label: '组件',
            children: [
              {
                value: 'basic',
                label: 'Basic',
                children: [
                  {
                    value: 'layout',
                    label: 'Layout 布局'
                  },
                  {
                    value: 'color',
                    label: 'Color 色彩'
                  },
                  {
                    value: 'typography',
                    label: 'Typography 字体'
                  },
                  {
                    value: 'icon',
                    label: 'Icon 图标'
                  },
                  {
                    value: 'button',
                    label: 'Button 按钮'
                  }
                ]
              },
              {
                value: 'form',
                label: 'Form',
                children: [
                  {
                    value: 'radio',
                    label: 'Radio 单选框'
                  },
                  {
                    value: 'checkbox',
                    label: 'Checkbox 多选框'
                  },
                  {
                    value: 'input',
                    label: 'Input 输入框'
                  },
                  {
                    value: 'input-number',
                    label: 'InputNumber 计数器'
                  },
                  {
                    value: 'select',
                    label: 'Select 选择器'
                  },
                  {
                    value: 'cascader',
                    label: 'Cascader 级联选择器'
                  },
                  {
                    value: 'switch',
                    label: 'Switch 开关'
                  },
                  {
                    value: 'slider',
                    label: 'Slider 滑块'
                  },
                  {
                    value: 'time-picker',
                    label: 'TimePicker 时间选择器'
                  },
                  {
                    value: 'date-picker',
                    label: 'DatePicker 日期选择器'
                  },
                  {
                    value: 'datetime-picker',
                    label: 'DateTimePicker 日期时间选择器'
                  },
                  {
                    value: 'upload',
                    label: 'Upload 上传'
                  },
                  {
                    value: 'rate',
                    label: 'Rate 评分'
                  },
                  {
                    value: 'form',
                    label: 'Form 表单'
                  }
                ]
              },
              {
                value: 'data',
                label: 'Data',
                children: [
                  {
                    value: 'table',
                    label: 'Table 表格'
                  },
                  {
                    value: 'tag',
                    label: 'Tag 标签'
                  },
                  {
                    value: 'progress',
                    label: 'Progress 进度条'
                  },
                  {
                    value: 'tree',
                    label: 'Tree 树形控件'
                  },
                  {
                    value: 'pagination',
                    label: 'Pagination 分页'
                  },
                  {
                    value: 'badge',
                    label: 'Badge 标记'
                  }
                ]
              },
              {
                value: 'notice',
                label: 'Notice',
                children: [
                  {
                    value: 'alert',
                    label: 'Alert 警告'
                  },
                  {
                    value: 'loading',
                    label: 'Loading 加载'
                  },
                  {
                    value: 'message',
                    label: 'Message 消息提示'
                  },
                  {
                    value: 'message-box',
                    label: 'MessageBox 弹框'
                  },
                  {
                    value: 'notification',
                    label: 'Notification 通知'
                  }
                ]
              },
              {
                value: 'navigation',
                label: 'Navigation',
                children: [
                  {
                    value: 'menu',
                    label: 'NavMenu 导航菜单'
                  },
                  {
                    value: 'tabs',
                    label: 'Tabs 标签页'
                  },
                  {
                    value: 'breadcrumb',
                    label: 'Breadcrumb 面包屑'
                  },
                  {
                    value: 'dropdown',
                    label: 'Dropdown 下拉菜单'
                  },
                  {
                    value: 'steps',
                    label: 'Steps 步骤条'
                  }
                ]
              },
              {
                value: 'others',
                label: 'Others',
                children: [
                  {
                    value: 'dialog',
                    label: 'Dialog 对话框'
                  },
                  {
                    value: 'tooltip',
                    label: 'Tooltip 文字提示'
                  },
                  {
                    value: 'popover',
                    label: 'Popover 弹出框'
                  },
                  {
                    value: 'card',
                    label: 'Card 卡片'
                  },
                  {
                    value: 'carousel',
                    label: 'Carousel 走马灯'
                  },
                  {
                    value: 'collapse',
                    label: 'Collapse 折叠面板'
                  }
                ]
              }
            ]
          },
          {
            value: 'ziyuan',
            label: '资源',
            children: [
              {
                value: 'axure',
                label: 'Axure Components'
              },
              {
                value: 'sketch',
                label: 'Sketch Templates'
              },
              {
                value: 'jiaohu',
                label: '组件交互文档'
              }
            ]
          }
        ]
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo4": (function() {
    
    const { createVNode: _createVNode, resolveComponent: _resolveComponent, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_cascader = _resolveComponent("el-cascader")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode("div", { class: "block" }, [
      _createVNode("span", { class: "demonstration" }, "默认显示所有Tag"),
      _createVNode(_component_el_cascader, {
        options: _ctx.options,
        props: _ctx.props,
        clearable: ""
      }, null, 8, ["options", "props"])
    ]),
    _createVNode("div", { class: "block" }, [
      _createVNode("span", { class: "demonstration" }, "折叠展示Tag"),
      _createVNode(_component_el_cascader, {
        options: _ctx.options,
        props: _ctx.props,
        "collapse-tags": "",
        clearable: ""
      }, null, 8, ["options", "props"])
    ])
  ]))
}
  
    const democomponentExport = {
    setup() {
      return {
        props: { multiple: true },
        options: [
          {
            value: 1,
            label: '东南',
            children: [
              {
                value: 2,
                label: '上海',
                children: [
                  { value: 3, label: '普陀' },
                  { value: 4, label: '黄埔' },
                  { value: 5, label: '徐汇' }
                ]
              },
              {
                value: 7,
                label: '江苏',
                children: [
                  { value: 8, label: '南京' },
                  { value: 9, label: '苏州' },
                  { value: 10, label: '无锡' }
                ]
              },
              {
                value: 12,
                label: '浙江',
                children: [
                  { value: 13, label: '杭州' },
                  { value: 14, label: '宁波' },
                  { value: 15, label: '嘉兴' }
                ]
              }
            ]
          },
          {
            value: 17,
            label: '西北',
            children: [
              {
                value: 18,
                label: '陕西',
                children: [
                  { value: 19, label: '西安' },
                  { value: 20, label: '延安' }
                ]
              },
              {
                value: 21,
                label: '新疆维吾尔族自治区',
                children: [
                  { value: 22, label: '乌鲁木齐' },
                  { value: 23, label: '克拉玛依' }
                ]
              }
            ]
          }
        ]
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo5": (function() {
    
    const { createVNode: _createVNode, resolveComponent: _resolveComponent, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_cascader = _resolveComponent("el-cascader")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode("div", { class: "block" }, [
      _createVNode("span", { class: "demonstration" }, "单选选择任意一级选项"),
      _createVNode(_component_el_cascader, {
        options: _ctx.options,
        props: { checkStrictly: true },
        clearable: ""
      }, null, 8, ["options"])
    ]),
    _createVNode("div", { class: "block" }, [
      _createVNode("span", { class: "demonstration" }, "多选选择任意一级选项"),
      _createVNode(_component_el_cascader, {
        options: _ctx.options,
        props: { multiple: true, checkStrictly: true },
        clearable: ""
      }, null, 8, ["options"])
    ])
  ]))
}
  
    const democomponentExport = {
    setup() {
      return {
        options: [
          {
            value: 'zhinan',
            label: '指南',
            children: [
              {
                value: 'shejiyuanze',
                label: '设计原则',
                children: [
                  {
                    value: 'yizhi',
                    label: '一致'
                  },
                  {
                    value: 'fankui',
                    label: '反馈'
                  },
                  {
                    value: 'xiaolv',
                    label: '效率'
                  },
                  {
                    value: 'kekong',
                    label: '可控'
                  }
                ]
              },
              {
                value: 'daohang',
                label: '导航',
                children: [
                  {
                    value: 'cexiangdaohang',
                    label: '侧向导航'
                  },
                  {
                    value: 'dingbudaohang',
                    label: '顶部导航'
                  }
                ]
              }
            ]
          },
          {
            value: 'zujian',
            label: '组件',
            children: [
              {
                value: 'basic',
                label: 'Basic',
                children: [
                  {
                    value: 'layout',
                    label: 'Layout 布局'
                  },
                  {
                    value: 'color',
                    label: 'Color 色彩'
                  },
                  {
                    value: 'typography',
                    label: 'Typography 字体'
                  },
                  {
                    value: 'icon',
                    label: 'Icon 图标'
                  },
                  {
                    value: 'button',
                    label: 'Button 按钮'
                  }
                ]
              },
              {
                value: 'form',
                label: 'Form',
                children: [
                  {
                    value: 'radio',
                    label: 'Radio 单选框'
                  },
                  {
                    value: 'checkbox',
                    label: 'Checkbox 多选框'
                  },
                  {
                    value: 'input',
                    label: 'Input 输入框'
                  },
                  {
                    value: 'input-number',
                    label: 'InputNumber 计数器'
                  },
                  {
                    value: 'select',
                    label: 'Select 选择器'
                  },
                  {
                    value: 'cascader',
                    label: 'Cascader 级联选择器'
                  },
                  {
                    value: 'switch',
                    label: 'Switch 开关'
                  },
                  {
                    value: 'slider',
                    label: 'Slider 滑块'
                  },
                  {
                    value: 'time-picker',
                    label: 'TimePicker 时间选择器'
                  },
                  {
                    value: 'date-picker',
                    label: 'DatePicker 日期选择器'
                  },
                  {
                    value: 'datetime-picker',
                    label: 'DateTimePicker 日期时间选择器'
                  },
                  {
                    value: 'upload',
                    label: 'Upload 上传'
                  },
                  {
                    value: 'rate',
                    label: 'Rate 评分'
                  },
                  {
                    value: 'form',
                    label: 'Form 表单'
                  }
                ]
              },
              {
                value: 'data',
                label: 'Data',
                children: [
                  {
                    value: 'table',
                    label: 'Table 表格'
                  },
                  {
                    value: 'tag',
                    label: 'Tag 标签'
                  },
                  {
                    value: 'progress',
                    label: 'Progress 进度条'
                  },
                  {
                    value: 'tree',
                    label: 'Tree 树形控件'
                  },
                  {
                    value: 'pagination',
                    label: 'Pagination 分页'
                  },
                  {
                    value: 'badge',
                    label: 'Badge 标记'
                  }
                ]
              },
              {
                value: 'notice',
                label: 'Notice',
                children: [
                  {
                    value: 'alert',
                    label: 'Alert 警告'
                  },
                  {
                    value: 'loading',
                    label: 'Loading 加载'
                  },
                  {
                    value: 'message',
                    label: 'Message 消息提示'
                  },
                  {
                    value: 'message-box',
                    label: 'MessageBox 弹框'
                  },
                  {
                    value: 'notification',
                    label: 'Notification 通知'
                  }
                ]
              },
              {
                value: 'navigation',
                label: 'Navigation',
                children: [
                  {
                    value: 'menu',
                    label: 'NavMenu 导航菜单'
                  },
                  {
                    value: 'tabs',
                    label: 'Tabs 标签页'
                  },
                  {
                    value: 'breadcrumb',
                    label: 'Breadcrumb 面包屑'
                  },
                  {
                    value: 'dropdown',
                    label: 'Dropdown 下拉菜单'
                  },
                  {
                    value: 'steps',
                    label: 'Steps 步骤条'
                  }
                ]
              },
              {
                value: 'others',
                label: 'Others',
                children: [
                  {
                    value: 'dialog',
                    label: 'Dialog 对话框'
                  },
                  {
                    value: 'tooltip',
                    label: 'Tooltip 文字提示'
                  },
                  {
                    value: 'popover',
                    label: 'Popover 弹出框'
                  },
                  {
                    value: 'card',
                    label: 'Card 卡片'
                  },
                  {
                    value: 'carousel',
                    label: 'Carousel 走马灯'
                  },
                  {
                    value: 'collapse',
                    label: 'Collapse 折叠面板'
                  }
                ]
              }
            ]
          },
          {
            value: 'ziyuan',
            label: '资源',
            children: [
              {
                value: 'axure',
                label: 'Axure Components'
              },
              {
                value: 'sketch',
                label: 'Sketch Templates'
              },
              {
                value: 'jiaohu',
                label: '组件交互文档'
              }
            ]
          }
        ]
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo6": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_cascader = _resolveComponent("el-cascader")

  return (_openBlock(), _createBlock(_component_el_cascader, { props: _ctx.props }, null, 8, ["props"]))
}
  
    let id = 0

  const democomponentExport = {
    setup() {
      const lazyLoad = (node, resolve) => {
        const { level } = node
        setTimeout(() => {
          const nodes = Array.from({ length: level + 1 }).map((item) => ({
            value: ++id,
            label: `选项${id}`,
            leaf: level >= 2
          }))
          // 通过调用resolve将子节点数据返回，通知组件数据加载完成
          resolve(nodes)
        }, 1000)
      }
      return {
        props: {
          lazy: true,
          lazyLoad
        }
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo7": (function() {
    
    const { createVNode: _createVNode, resolveComponent: _resolveComponent, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_cascader = _resolveComponent("el-cascader")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode("div", { class: "block" }, [
      _createVNode("span", { class: "demonstration" }, "单选可搜索"),
      _createVNode(_component_el_cascader, {
        placeholder: "试试搜索：指南",
        options: _ctx.options,
        filterable: ""
      }, null, 8, ["options"])
    ]),
    _createVNode("div", { class: "block" }, [
      _createVNode("span", { class: "demonstration" }, "多选可搜索"),
      _createVNode(_component_el_cascader, {
        placeholder: "试试搜索：指南",
        options: _ctx.options,
        props: { multiple: true },
        filterable: ""
      }, null, 8, ["options"])
    ])
  ]))
}
  
    const democomponentExport = {
    setup() {
      return {
        options: [
          {
            value: 'zhinan',
            label: '指南',
            children: [
              {
                value: 'shejiyuanze',
                label: '设计原则',
                children: [
                  {
                    value: 'yizhi',
                    label: '一致'
                  },
                  {
                    value: 'fankui',
                    label: '反馈'
                  },
                  {
                    value: 'xiaolv',
                    label: '效率'
                  },
                  {
                    value: 'kekong',
                    label: '可控'
                  }
                ]
              },
              {
                value: 'daohang',
                label: '导航',
                children: [
                  {
                    value: 'cexiangdaohang',
                    label: '侧向导航'
                  },
                  {
                    value: 'dingbudaohang',
                    label: '顶部导航'
                  }
                ]
              }
            ]
          },
          {
            value: 'zujian',
            label: '组件',
            children: [
              {
                value: 'basic',
                label: 'Basic',
                children: [
                  {
                    value: 'layout',
                    label: 'Layout 布局'
                  },
                  {
                    value: 'color',
                    label: 'Color 色彩'
                  },
                  {
                    value: 'typography',
                    label: 'Typography 字体'
                  },
                  {
                    value: 'icon',
                    label: 'Icon 图标'
                  },
                  {
                    value: 'button',
                    label: 'Button 按钮'
                  }
                ]
              },
              {
                value: 'form',
                label: 'Form',
                children: [
                  {
                    value: 'radio',
                    label: 'Radio 单选框'
                  },
                  {
                    value: 'checkbox',
                    label: 'Checkbox 多选框'
                  },
                  {
                    value: 'input',
                    label: 'Input 输入框'
                  },
                  {
                    value: 'input-number',
                    label: 'InputNumber 计数器'
                  },
                  {
                    value: 'select',
                    label: 'Select 选择器'
                  },
                  {
                    value: 'cascader',
                    label: 'Cascader 级联选择器'
                  },
                  {
                    value: 'switch',
                    label: 'Switch 开关'
                  },
                  {
                    value: 'slider',
                    label: 'Slider 滑块'
                  },
                  {
                    value: 'time-picker',
                    label: 'TimePicker 时间选择器'
                  },
                  {
                    value: 'date-picker',
                    label: 'DatePicker 日期选择器'
                  },
                  {
                    value: 'datetime-picker',
                    label: 'DateTimePicker 日期时间选择器'
                  },
                  {
                    value: 'upload',
                    label: 'Upload 上传'
                  },
                  {
                    value: 'rate',
                    label: 'Rate 评分'
                  },
                  {
                    value: 'form',
                    label: 'Form 表单'
                  }
                ]
              },
              {
                value: 'data',
                label: 'Data',
                children: [
                  {
                    value: 'table',
                    label: 'Table 表格'
                  },
                  {
                    value: 'tag',
                    label: 'Tag 标签'
                  },
                  {
                    value: 'progress',
                    label: 'Progress 进度条'
                  },
                  {
                    value: 'tree',
                    label: 'Tree 树形控件'
                  },
                  {
                    value: 'pagination',
                    label: 'Pagination 分页'
                  },
                  {
                    value: 'badge',
                    label: 'Badge 标记'
                  }
                ]
              },
              {
                value: 'notice',
                label: 'Notice',
                children: [
                  {
                    value: 'alert',
                    label: 'Alert 警告'
                  },
                  {
                    value: 'loading',
                    label: 'Loading 加载'
                  },
                  {
                    value: 'message',
                    label: 'Message 消息提示'
                  },
                  {
                    value: 'message-box',
                    label: 'MessageBox 弹框'
                  },
                  {
                    value: 'notification',
                    label: 'Notification 通知'
                  }
                ]
              },
              {
                value: 'navigation',
                label: 'Navigation',
                children: [
                  {
                    value: 'menu',
                    label: 'NavMenu 导航菜单'
                  },
                  {
                    value: 'tabs',
                    label: 'Tabs 标签页'
                  },
                  {
                    value: 'breadcrumb',
                    label: 'Breadcrumb 面包屑'
                  },
                  {
                    value: 'dropdown',
                    label: 'Dropdown 下拉菜单'
                  },
                  {
                    value: 'steps',
                    label: 'Steps 步骤条'
                  }
                ]
              },
              {
                value: 'others',
                label: 'Others',
                children: [
                  {
                    value: 'dialog',
                    label: 'Dialog 对话框'
                  },
                  {
                    value: 'tooltip',
                    label: 'Tooltip 文字提示'
                  },
                  {
                    value: 'popover',
                    label: 'Popover 弹出框'
                  },
                  {
                    value: 'card',
                    label: 'Card 卡片'
                  },
                  {
                    value: 'carousel',
                    label: 'Carousel 走马灯'
                  },
                  {
                    value: 'collapse',
                    label: 'Collapse 折叠面板'
                  }
                ]
              }
            ]
          },
          {
            value: 'ziyuan',
            label: '资源',
            children: [
              {
                value: 'axure',
                label: 'Axure Components'
              },
              {
                value: 'sketch',
                label: 'Sketch Templates'
              },
              {
                value: 'jiaohu',
                label: '组件交互文档'
              }
            ]
          }
        ]
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo8": (function() {
    
    const { toDisplayString: _toDisplayString, createVNode: _createVNode, openBlock: _openBlock, createBlock: _createBlock, createCommentVNode: _createCommentVNode, resolveComponent: _resolveComponent, withCtx: _withCtx } = Vue

function render (_ctx, _cache) {
  const _component_el_cascader = _resolveComponent("el-cascader")

  return (_openBlock(), _createBlock(_component_el_cascader, { options: _ctx.options }, {
    default: _withCtx(({ node, data }) => [
      _createVNode("span", null, _toDisplayString(data.label), 1),
      (!node.isLeaf)
        ? (_openBlock(), _createBlock("span", { key: 0 }, " (" + _toDisplayString(data.children.length) + ") ", 1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["options"]))
}
  
    const democomponentExport = {
    setup() {
      return {
        options: [
          {
            value: 'zhinan',
            label: '指南',
            children: [
              {
                value: 'shejiyuanze',
                label: '设计原则',
                children: [
                  {
                    value: 'yizhi',
                    label: '一致'
                  },
                  {
                    value: 'fankui',
                    label: '反馈'
                  },
                  {
                    value: 'xiaolv',
                    label: '效率'
                  },
                  {
                    value: 'kekong',
                    label: '可控'
                  }
                ]
              },
              {
                value: 'daohang',
                label: '导航',
                children: [
                  {
                    value: 'cexiangdaohang',
                    label: '侧向导航'
                  },
                  {
                    value: 'dingbudaohang',
                    label: '顶部导航'
                  }
                ]
              }
            ]
          },
          {
            value: 'zujian',
            label: '组件',
            children: [
              {
                value: 'basic',
                label: 'Basic',
                children: [
                  {
                    value: 'layout',
                    label: 'Layout 布局'
                  },
                  {
                    value: 'color',
                    label: 'Color 色彩'
                  },
                  {
                    value: 'typography',
                    label: 'Typography 字体'
                  },
                  {
                    value: 'icon',
                    label: 'Icon 图标'
                  },
                  {
                    value: 'button',
                    label: 'Button 按钮'
                  }
                ]
              },
              {
                value: 'form',
                label: 'Form',
                children: [
                  {
                    value: 'radio',
                    label: 'Radio 单选框'
                  },
                  {
                    value: 'checkbox',
                    label: 'Checkbox 多选框'
                  },
                  {
                    value: 'input',
                    label: 'Input 输入框'
                  },
                  {
                    value: 'input-number',
                    label: 'InputNumber 计数器'
                  },
                  {
                    value: 'select',
                    label: 'Select 选择器'
                  },
                  {
                    value: 'cascader',
                    label: 'Cascader 级联选择器'
                  },
                  {
                    value: 'switch',
                    label: 'Switch 开关'
                  },
                  {
                    value: 'slider',
                    label: 'Slider 滑块'
                  },
                  {
                    value: 'time-picker',
                    label: 'TimePicker 时间选择器'
                  },
                  {
                    value: 'date-picker',
                    label: 'DatePicker 日期选择器'
                  },
                  {
                    value: 'datetime-picker',
                    label: 'DateTimePicker 日期时间选择器'
                  },
                  {
                    value: 'upload',
                    label: 'Upload 上传'
                  },
                  {
                    value: 'rate',
                    label: 'Rate 评分'
                  },
                  {
                    value: 'form',
                    label: 'Form 表单'
                  }
                ]
              },
              {
                value: 'data',
                label: 'Data',
                children: [
                  {
                    value: 'table',
                    label: 'Table 表格'
                  },
                  {
                    value: 'tag',
                    label: 'Tag 标签'
                  },
                  {
                    value: 'progress',
                    label: 'Progress 进度条'
                  },
                  {
                    value: 'tree',
                    label: 'Tree 树形控件'
                  },
                  {
                    value: 'pagination',
                    label: 'Pagination 分页'
                  },
                  {
                    value: 'badge',
                    label: 'Badge 标记'
                  }
                ]
              },
              {
                value: 'notice',
                label: 'Notice',
                children: [
                  {
                    value: 'alert',
                    label: 'Alert 警告'
                  },
                  {
                    value: 'loading',
                    label: 'Loading 加载'
                  },
                  {
                    value: 'message',
                    label: 'Message 消息提示'
                  },
                  {
                    value: 'message-box',
                    label: 'MessageBox 弹框'
                  },
                  {
                    value: 'notification',
                    label: 'Notification 通知'
                  }
                ]
              },
              {
                value: 'navigation',
                label: 'Navigation',
                children: [
                  {
                    value: 'menu',
                    label: 'NavMenu 导航菜单'
                  },
                  {
                    value: 'tabs',
                    label: 'Tabs 标签页'
                  },
                  {
                    value: 'breadcrumb',
                    label: 'Breadcrumb 面包屑'
                  },
                  {
                    value: 'dropdown',
                    label: 'Dropdown 下拉菜单'
                  },
                  {
                    value: 'steps',
                    label: 'Steps 步骤条'
                  }
                ]
              },
              {
                value: 'others',
                label: 'Others',
                children: [
                  {
                    value: 'dialog',
                    label: 'Dialog 对话框'
                  },
                  {
                    value: 'tooltip',
                    label: 'Tooltip 文字提示'
                  },
                  {
                    value: 'popover',
                    label: 'Popover 弹出框'
                  },
                  {
                    value: 'card',
                    label: 'Card 卡片'
                  },
                  {
                    value: 'carousel',
                    label: 'Carousel 走马灯'
                  },
                  {
                    value: 'collapse',
                    label: 'Collapse 折叠面板'
                  }
                ]
              }
            ]
          },
          {
            value: 'ziyuan',
            label: '资源',
            children: [
              {
                value: 'axure',
                label: 'Axure Components'
              },
              {
                value: 'sketch',
                label: 'Sketch Templates'
              },
              {
                value: 'jiaohu',
                label: '组件交互文档'
              }
            ]
          }
        ]
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo9": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_cascader_panel = _resolveComponent("el-cascader-panel")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_el_cascader_panel, { options: _ctx.options }, null, 8, ["options"])
  ]))
}
  
    const democomponentExport = {
    setup() {
      return {
        options: [
          {
            value: 'zhinan',
            label: '指南',
            children: [
              {
                value: 'shejiyuanze',
                label: '设计原则',
                children: [
                  {
                    value: 'yizhi',
                    label: '一致'
                  },
                  {
                    value: 'fankui',
                    label: '反馈'
                  },
                  {
                    value: 'xiaolv',
                    label: '效率'
                  },
                  {
                    value: 'kekong',
                    label: '可控'
                  }
                ]
              },
              {
                value: 'daohang',
                label: '导航',
                children: [
                  {
                    value: 'cexiangdaohang',
                    label: '侧向导航'
                  },
                  {
                    value: 'dingbudaohang',
                    label: '顶部导航'
                  }
                ]
              }
            ]
          },
          {
            value: 'zujian',
            label: '组件',
            children: [
              {
                value: 'basic',
                label: 'Basic',
                children: [
                  {
                    value: 'layout',
                    label: 'Layout 布局'
                  },
                  {
                    value: 'color',
                    label: 'Color 色彩'
                  },
                  {
                    value: 'typography',
                    label: 'Typography 字体'
                  },
                  {
                    value: 'icon',
                    label: 'Icon 图标'
                  },
                  {
                    value: 'button',
                    label: 'Button 按钮'
                  }
                ]
              },
              {
                value: 'form',
                label: 'Form',
                children: [
                  {
                    value: 'radio',
                    label: 'Radio 单选框'
                  },
                  {
                    value: 'checkbox',
                    label: 'Checkbox 多选框'
                  },
                  {
                    value: 'input',
                    label: 'Input 输入框'
                  },
                  {
                    value: 'input-number',
                    label: 'InputNumber 计数器'
                  },
                  {
                    value: 'select',
                    label: 'Select 选择器'
                  },
                  {
                    value: 'cascader',
                    label: 'Cascader 级联选择器'
                  },
                  {
                    value: 'switch',
                    label: 'Switch 开关'
                  },
                  {
                    value: 'slider',
                    label: 'Slider 滑块'
                  },
                  {
                    value: 'time-picker',
                    label: 'TimePicker 时间选择器'
                  },
                  {
                    value: 'date-picker',
                    label: 'DatePicker 日期选择器'
                  },
                  {
                    value: 'datetime-picker',
                    label: 'DateTimePicker 日期时间选择器'
                  },
                  {
                    value: 'upload',
                    label: 'Upload 上传'
                  },
                  {
                    value: 'rate',
                    label: 'Rate 评分'
                  },
                  {
                    value: 'form',
                    label: 'Form 表单'
                  }
                ]
              },
              {
                value: 'data',
                label: 'Data',
                children: [
                  {
                    value: 'table',
                    label: 'Table 表格'
                  },
                  {
                    value: 'tag',
                    label: 'Tag 标签'
                  },
                  {
                    value: 'progress',
                    label: 'Progress 进度条'
                  },
                  {
                    value: 'tree',
                    label: 'Tree 树形控件'
                  },
                  {
                    value: 'pagination',
                    label: 'Pagination 分页'
                  },
                  {
                    value: 'badge',
                    label: 'Badge 标记'
                  }
                ]
              },
              {
                value: 'notice',
                label: 'Notice',
                children: [
                  {
                    value: 'alert',
                    label: 'Alert 警告'
                  },
                  {
                    value: 'loading',
                    label: 'Loading 加载'
                  },
                  {
                    value: 'message',
                    label: 'Message 消息提示'
                  },
                  {
                    value: 'message-box',
                    label: 'MessageBox 弹框'
                  },
                  {
                    value: 'notification',
                    label: 'Notification 通知'
                  }
                ]
              },
              {
                value: 'navigation',
                label: 'Navigation',
                children: [
                  {
                    value: 'menu',
                    label: 'NavMenu 导航菜单'
                  },
                  {
                    value: 'tabs',
                    label: 'Tabs 标签页'
                  },
                  {
                    value: 'breadcrumb',
                    label: 'Breadcrumb 面包屑'
                  },
                  {
                    value: 'dropdown',
                    label: 'Dropdown 下拉菜单'
                  },
                  {
                    value: 'steps',
                    label: 'Steps 步骤条'
                  }
                ]
              },
              {
                value: 'others',
                label: 'Others',
                children: [
                  {
                    value: 'dialog',
                    label: 'Dialog 对话框'
                  },
                  {
                    value: 'tooltip',
                    label: 'Tooltip 文字提示'
                  },
                  {
                    value: 'popover',
                    label: 'Popover 弹出框'
                  },
                  {
                    value: 'card',
                    label: 'Card 卡片'
                  },
                  {
                    value: 'carousel',
                    label: 'Carousel 走马灯'
                  },
                  {
                    value: 'collapse',
                    label: 'Collapse 折叠面板'
                  }
                ]
              }
            ]
          },
          {
            value: 'ziyuan',
            label: '资源',
            children: [
              {
                value: 'axure',
                label: 'Axure Components'
              },
              {
                value: 'sketch',
                label: 'Sketch Templates'
              },
              {
                value: 'jiaohu',
                label: '组件交互文档'
              }
            ]
          }
        ]
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),
        }
      }
    </script>
  